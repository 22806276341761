import React from 'react';

import { Main, Title, Count, ToggleIcon, Columns, Column, Content, Text } from './styles';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

interface AccordionSummaryProps {
    count: number;
    title: string;
    isActive: boolean;
    onClick: () => void;
    children: React.ReactNode;
}

const AccordionSummary: React.FC<AccordionSummaryProps> =
    ({ count, title, isActive, onClick, children }: AccordionSummaryProps) => {

        return (
            <Main type='button' onClick={onClick} className="accordionSummary">
                <Columns className='columns'>
                    <Column className='columnCount'>
                        <Count className='count'>
                            {count}
                        </Count>
                        <Title>{title}</Title>
                    </Column>
                    <Column className='columnIcon'>
                        <ToggleIcon className='toggleDiv'>
                            <I icon={
                                !isActive
                                    ? ['fas', 'plus-circle']
                                    : ['fas', 'minus-circle']
                            } style={{ height: '1.625rem' }} 
                            className='toggleIcon'/>
                        </ToggleIcon>
                    </Column>
                </Columns>
                {isActive && (
                    <Content>
                        <Text>
                            {children}
                        </Text>
                    </Content>
                )}
            </Main>
        )
    }

export { AccordionSummary };