import styled from 'styled-components';

import { theme } from '@theme';

const { color, border, zIndex } = theme;

export const Main = styled.div`
    width: 100%;
    height: auto;
    margin-bottom: 20px;
`;

export const Title = styled.span`
    color: ${color.grey[700]};
    font-size: .8125rem;
    margin-bottom: .3125rem;
    display: block;
`;

export const WrapperChildren = styled.div`
    position: relative;
`;

export const WrapAnchorPicture = styled.div`
    height: 100vh;
    max-height: 84px;
    width: 100%;
    overflow: hidden;
    position: relative;

    & > .tag-image-slider-type {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        border: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top-left-radius: 4px;
        border-bottom-right-radius: 4px;
        font-size: 10px;
        padding: 2px 5px;
        background-color: ${color.grey[100]};
        cursor: pointer;
        white-space: nowrap;
    }
    
    /* &:hover {
        border: .0625rem dashed ${color.grey[600]};
    } */

    & > .AnchorPicture {
        display: block;
        height: 100%;
        width: 100%;
        overflow: hidden;
        
        & > .WrapPicture {
            height: 100%;
            width: 100%;
            picture {
                display: contents;
                
                source,
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border: 0;
                    max-width: 100%;
                    vertical-align: middle;
                    display: table-caption !important;
                    font-size: .75rem;
                    color: ${color.grey[700]};;
                }
            }
        }
    }

    &.mobile,
    &.desktop {
        &:hover {
            
            & > .overlay {
                opacity: 1;
                -webkit-transition: all 0.20s ease-in-out;
                -moz-transition: all 0.20s ease-in-out;
                -ms-transition: all 0.20s ease-in-out;
                -o-transition: all 0.20s ease-in-out;
                transition: all 0.20s ease-in-out;
            }
        }

    }
    
`;

export const Label = styled.button`
    position: relative;
    width: 100%;
    background-color: transparent;
    border: .0625rem dashed ${color.grey[400]};
    border-radius: ${border.input};
    padding: 0;
`;

export const Form = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-top: .0625rem dashed ${color.grey[400]};

    & > input {
        width: 100%;
        background-color: ${color.common.white};
        border: 0;
        padding: 4px 10px;
        font-size: 12px;
    }

    & > button {
        border: 0;
        background-color: ${color.grey[400]};
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        font-size: 12px;
        padding: 2px 5px;
        background-color: ${color.grey[300]};
        cursor: pointer;
        white-space: nowrap;

        & > svg {
            width: 12px;
            height: auto;
            margin-left: 6px;
        }
    }

`;

export const Overlay = styled.div`
    background-color: rgba(0,0,0,0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: ${zIndex.max};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${border.input};
    opacity: 0;
    -webkit-transition: all 0.20s ease-in-out;
    -moz-transition: all 0.20s ease-in-out;
    -ms-transition: all 0.20s ease-in-out;
    -o-transition: all 0.20s ease-in-out;
    transition: all 0.20s ease-in-out;
    cursor: pointer;
    border-top-left-radius: 0;
        border-top-right-radius: 0;
`;

export const OverlayContent = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    & > button {
        height: 34px;
        width: 34px;
        border-radius: .25rem;
        padding: 0;
        border: 0;
        background-color: ${color.common.white};
        cursor: pointer;
        
        
        &:last-child {
            margin-left: 10px;
        }
        
        & > label {
            margin: 0;
            cursor: pointer;
            height: 34px;
            width: 34px;
            display: flex;
            align-items: center;
            justify-content: center;

            & > input {
                display: none;
                opacity: 0;
                visibility: hidden;
            } 
        }
    }
`;