import styled from "styled-components";

import {theme} from '@theme';

export const Main = styled.div`
    ${theme.breakpoints.down('md', `
        margin-bottom: 15px;
    `)}
`;

export const BoxContainer= styled.div`
    width: 100%;
    height: 100%;
    border-radius: ${theme.border.default};
    display: flex;
`;

export const BoxIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 80px;
    border-radius: ${theme.border.default} 0 0 ${theme.border.default};
`;
