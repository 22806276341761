import React from 'react';

import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';

import { Main, Head, Content, URLAddress, BrowserActions } from './styles';
import { theme } from '@theme';

type ScreenSize = "desktop" | "mobile";

interface BrowserProps {
    urlAddress: string;
    screenWidth: ScreenSize;
    children: React.ReactNode;
}

const Browser: React.FC<BrowserProps> = ({ urlAddress, screenWidth, children }: BrowserProps) => {

    const { color } = theme;
    const size = (screenWidth === "desktop" && "100%") || (screenWidth === "mobile" && "375px") as string;

    return (
        <Main screenWidth={size}>
            <Head ScreenSize={screenWidth}>
                <BrowserActions ScreenSize={screenWidth}>
                    {screenWidth === "desktop"
                        ? (
                            <>
                                <I icon={faCircle as any} color={color.grey[400]} />
                                <I icon={faCircle as any} color={color.grey[400]} />
                                <I icon={faCircle as any} color={color.grey[400]} />
                            </>
                        )
                        : (
                            <I icon={faEllipsisV as any} color={color.grey[400]} />
                        )}
                </BrowserActions>
                <URLAddress>{urlAddress ? urlAddress : "https://meusite.campanhasonline.com.br"}</URLAddress>
            </Head>

            <Content className='fake-browser-content'>

                {children}
            </Content>
        </Main>
    )
}

export { Browser };