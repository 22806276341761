import styled from 'styled-components';
import { theme } from '@theme';

const { color, zIndex } = theme;

export const Main = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${color.grey[50]};
  height: 100%;
  width: 100%;
  z-index: ${zIndex.max};
  overflow: hidden;
`;

export const Columns = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  height: 100%;
`;

export const Column = styled.div`
  &:nth-child(2) {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    margin: 1.875rem 3.125rem 6.25rem 3.125rem;
  }

`;

export const SidebarContentForm = styled.form`
    padding: .625rem 1.5625rem;
    background-color: ${color.common.white};
    height: 100%;
`;