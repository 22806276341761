
import { hotsiteTheme } from '@data/theme';

const JSONFields = () => {

    const { header, heroSlider, section1, section2, faq } = hotsiteTheme;

    const fields = [
        {
            name: "Header",
            sectionID: "header",
            id: 8,
            child: [
                {
                    title: 'Geral',
                    fields: [
                        {
                            type: 'file',
                            label: "Logo",
                            name: 'headerLogo',
                            value: header.headerLogo,
                            id: "headerLogo",
                        },
                        {
                            type: 'color',
                            label: "Cor de fundo",
                            name: 'headerBackgroundColor',
                            value: header.headerBackgroundColor,
                            id: "headerBackgroundColor",
                        },
                    ]
                },

                {
                    title: 'Menu',
                    fields: [
                        {
                            type: 'color',
                            label: "Cor links do menu",
                            name: 'headerNavColor',
                            value: header.headerNav.headerNavColor,
                            id: "headerNavColor",
                        },
                        {
                            type: 'color',
                            label: "Cor link ativo do menu",
                            name: 'headerNavActiveColor',
                            value: header.headerNav.headerNavActiveColor,
                            id: "headerNavActiveColor",
                        },
                    ]
                },

                {
                    title: 'Botões',
                    fields: [
                        {
                            type: 'color',
                            label: "Criar conta - Borda",
                            name: 'headerButtonCreateAccountBorderColor',
                            value: header.headerButtonCreateAccount.headerButtonCreateAccountBorderColor,
                            id: "headerButtonCreateAccountBorderColor",
                        },
                        {
                            type: 'color',
                            label: "Criar conta - Text",
                            name: 'headerButtonCreateAccountColor',
                            value: header.headerButtonCreateAccount.headerButtonCreateAccountColor,
                            id: "headerButtonCreateAccountColor",
                        },
                        {
                            type: 'color',
                            label: "Acesse - Borda",
                            name: 'headerButtonAccessBorderColor',
                            value: header.headerButtonAccess.headerButtonAccessBorderColor,
                            id: "headerButtonAccessBorderColor",
                        },
                        {
                            type: 'color',
                            label: "Acesse - Text",
                            name: 'headerButtonAccessColor',
                            value: header.headerButtonAccess.headerButtonAccessColor,
                            id: "headerButtonAccessColor",
                        },
                    ]
                },

            ],
        },
        {
            name: "Slider",
            sectionID: "heroSlider",
            id: 8,
            child: [
                {
                    title: 'Slider #1',
                    fields: [
                        {
                            type: 'file-slider',
                            label: "Imagem Desktop",
                            name: 'src',
                            value: heroSlider[0].src,
                            id: "src",
                        },
                    ]
                },
            ],
        },
        {
            name: "Como Funciona",
            sectionID: "section1",
            id: 8,
            child: [
                {
                    title: 'Geral',
                    fields: [
                        {
                            type: 'color',
                            label: "Titulo",
                            name: 'section1HeadlineColor',
                            value: section1.section1Headline.section1HeadlineColor,
                            id: "section1HeadlineColor",
                        },
                    ]
                },
                {
                    title: 'Boxes',
                    fields: [
                        {
                            type: 'color',
                            label: "Cor do box",
                            name: 'section1StylesBackgroundColor',
                            value: section1.section1Styles.section1StylesBackgroundColor,
                            id: "section1StylesBackgroundColor",
                        },
                        {
                            type: 'color',
                            label: "Cor titulo passos",
                            name: 'section1StylesStepColor',
                            value: section1.section1Styles.section1StylesStepColor,
                            id: "section1StylesStepColor",
                        },
                        {
                            type: 'color',
                            label: "Cor titulo",
                            name: 'section1StylesTitleColor',
                            value: section1.section1Styles.section1StylesTitleColor,
                            id: "section1StylesTitleColor",
                        },
                        {
                            type: 'color',
                            label: "Cor das setas",
                            name: 'section1StylesArrowsColors',
                            value: section1.section1Styles.section1StylesArrowsColors,
                            id: "section1StylesArrowsColors",
                        },
                    ]
                },
            ],
        },
        {
            name: "Campanhas Ativas",
            sectionID: "section2",
            id: 8,
            child: [
                {
                    title: 'Geral',
                    fields: [
                        {
                            type: 'color',
                            label: "Titulo",
                            name: 'section2HeadlineColor',
                            value: section2.section2Headline.section2HeadlineColor,
                            id: "section2HeadlineColor",
                        },
                    ]
                },
                {
                    title: 'Campanhas',
                    fields: [
                        {
                            type: 'color',
                            label: "Cor do botão",
                            name: 'section2StylesButtonComponentBackgroundColor',
                            value: section2.section2Styles.section2StylesButtonComponent.section2StylesButtonComponentBackgroundColor,
                            id: "section2StylesButtonComponentBackgroundColor",
                        },
                        {
                            type: 'color',
                            label: "Cor do texto",
                            name: 'section2StylesButtonComponentColor',
                            value: section2.section2Styles.section2StylesButtonComponent.section2StylesButtonComponentColor,
                            id: "section2StylesButtonComponentColor",
                        },
                    ]
                },
            ],
        },
        {
            name: "FAQ",
            sectionID: "faq",
            id: 8,
            child: [
                {
                    title: 'Geral',
                    fields: [
                        {
                            type: 'color',
                            label: "Titulo",
                            name: 'faqTitleColor',
                            value: faq.faqTitle.faqTitleColor,
                            id: "faqTitleColor",
                        },
                    ]
                },
                {
                    title: 'Perguntas e Respostas',
                    fields: [
                        {
                            type: 'color',
                            label: "Cor dos icones",
                            name: 'faqAccordionIconColor',
                            value: faq.faqAccordion.faqAccordionIconColor,
                            id: "faqAccordionIconColor",
                        },
                    ]
                },
            ],
        },
    ]

    return { fields }
}

export { JSONFields }