import Service from './Service';

interface ListCampaignProps {
    subdomain: string;
}

interface CampaignDataProps {
    subdomain: string;
    campaign: string;
    fileLoad: string;
}

class Campaign extends Service {

    listCampaigns({ subdomain }: ListCampaignProps) {
        return this.axiosInstance.get(`/campaign/website?websiteAlias=${subdomain}`);
    }

    listFiles({ subdomain, campaign, fileLoad }: CampaignDataProps) {
        return this.axiosInstance.get(`/files/${subdomain}/${campaign}/${fileLoad}`);
    }
    
    getRegulament({ subdomain }: ListCampaignProps) {
        return this.axiosInstance.get(`/campaign/regulament?websiteAlias=${subdomain}`);
    }
    
    listLoyaltyPartner({subdomain}: ListCampaignProps) {
        return this.axiosInstance.get(`/campaign/loyalty?websiteAlias=${subdomain}`);
    }
}

export default Campaign;