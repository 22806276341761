import React from 'react';
import { maxText } from '@utils';
import { Main, CardHead, CardMedia, CardContent, Title, Description } from './styles';

import Placeholder640x410 from '@img/placeholder-640x410.png';

interface CardProps {
    image: string;
    title: string;
    description: string;
    children?: React.ReactNode;
}

const Card: React.FC<CardProps> = ({ image, title, description, children }: CardProps) => {

    return (
        <Main className='cardSlider'>
            <CardHead>
                <CardMedia>
                    <div className="WrapAnchorPicture">
                        <div className="AnchorPicture">
                            <div className="WrapPicture">
                                <picture>
                                    <img
                                        src={image}
                                        alt={title}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = Placeholder640x410;
                                        }}
                                    />
                                </picture>
                            </div>
                        </div>
                    </div>
                </CardMedia>
            </CardHead>
            <CardContent className='cardContent'>
                <Title>{title}</Title>
                <Description>
                    {maxText({value: description, maxChars: 90})}
                </Description>
                {children}
            </CardContent>
        </Main>
    )
}

export { Card };