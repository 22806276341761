import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/pro-regular-svg-icons';

import {
    Main,
    WrapperChildren,
    Title,
    Label,
    WrapAnchorPicture,
    Overlay,
    OverlayContent,
    OverlayText
} from './styles';

interface UploadFieldProps {
    label: string;
    id: string;
    name: string;
    previewSrc: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const UploadField: React.FC<UploadFieldProps> = ({ label, id, name, previewSrc, onChange }: UploadFieldProps) => {

    return (
        <Main>
            <Title>{label}</Title>
            <Label htmlFor={id}>

                <WrapperChildren>
                <Overlay className='overlay'>
                    <OverlayContent>
                        <I icon={faUpload as any} color="#FFFFFF" />
                        <OverlayText> Trocar imagem</OverlayText>
                    </OverlayContent>
                </Overlay>
                    <input type='file' id={id} name={name} onChange={onChange} />

                    <WrapAnchorPicture className="WrapAnchorPicture">
                        <div className="AnchorPicture">
                            <div className="WrapPicture">
                                <picture>
                                    <LazyLoadImage
                                        src={previewSrc}
                                        alt="image theme"
                                    />
                                </picture>
                            </div>
                        </div>
                    </WrapAnchorPicture>
                </WrapperChildren>

            </Label>
        </Main >
    )
}

export { UploadField };