import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { faCheck, faTrashAlt, faEdit } from '@fortawesome/pro-regular-svg-icons';

import {
    Main,
    WrapperChildren,
    Title,
    Label,
    WrapAnchorPicture,
    Form,
    Overlay,
    OverlayContent,
} from './styles';

interface UploadPreviewProps {
    title: string;
    previewSrc: string;
    mobilePreviewSrc: string;
    deleteDesktopSlide: () => void;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeDesktop: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onChangeMobile: (event: React.ChangeEvent<HTMLInputElement>) => void;
    deleteMobileSlide: () => void;
    saveLink: () => void;
    deskId: string;
    moblId: string;
}

const UploadPreview: React.FC<UploadPreviewProps> = ({
    title,
    previewSrc,
    mobilePreviewSrc,
    deleteMobileSlide,
    deleteDesktopSlide,
    onChange,
    onChangeMobile,
    onChangeDesktop,
    saveLink,
    deskId,
    moblId,
}: UploadPreviewProps) => {

    const [statusButtonSave, setStatusButtonSave] = React.useState(false);

    const handleButtonSave = () => {
        saveLink();
        setStatusButtonSave(prev => prev = true)
        setTimeout(() => setStatusButtonSave(prev => prev = false), 1000);
    }

    return (
        <Main className='preview'>
            <Title>{title}</Title>
            <Label type='button' >

                <WrapperChildren>

                    <WrapAnchorPicture className="WrapAnchorPicture desktop">

                        <Overlay className='overlay'>

                            <OverlayContent>
                                <button type='button' className='edit'>
                                    <label htmlFor={deskId}>
                                        <input type='file' name='input-desktop-change' id={deskId} onChange={onChangeDesktop} />
                                        <I icon={faEdit as any} color="#212121" />
                                    </label>
                                </button>
                                <button type='button' className='edit' onClick={deleteDesktopSlide}>
                                    <I icon={faTrashAlt as any} color="#212121" />
                                </button>
                            </OverlayContent>

                        </Overlay>

                        <span className='tag-image-slider-type'>Desktop</span>

                        <div className="AnchorPicture">

                            <div className="WrapPicture">
                                <picture>
                                    <LazyLoadImage
                                        src={previewSrc}
                                        alt="image theme"
                                    />
                                </picture>
                            </div>
                        </div>

                    </WrapAnchorPicture>


                    <WrapAnchorPicture className="WrapAnchorPicture mobile">

                        <Overlay className='overlay'>
                            <OverlayContent>
                                <button type='button' className='edit'>
                                    <label htmlFor={moblId}>
                                        <input type='file' placeholder='imagem mobile' name='input-mobile' id={moblId} onChange={onChangeMobile} />
                                        <I icon={faEdit as any} color="#212121" />
                                    </label>
                                </button>
                                <button type='button' className='edit' onClick={deleteMobileSlide}>
                                    <I icon={faTrashAlt as any} color="#212121" />
                                </button>
                            </OverlayContent>
                        </Overlay>

                        <span className='tag-image-slider-type'>Mobile</span>

                        <div className="AnchorPicture">
                            <div className="WrapPicture">
                                <picture>
                                    <LazyLoadImage
                                        src={mobilePreviewSrc}
                                        alt="image theme name"
                                    />
                                </picture>
                            </div>
                        </div>
                    </WrapAnchorPicture>

                    <Form>
                        <input type='text' placeholder='adicionar link' name='input-banner-link' onChange={onChange} />
                        <button type='button' onClick={handleButtonSave}>
                            {!statusButtonSave
                                ? ["Editar", <I icon={faCheck as any} />]
                                : "Link Editado"
                            }
                        </button>
                    </Form>
                </WrapperChildren>

            </Label>
        </Main >
    )
}


export { UploadPreview };