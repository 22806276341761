import config from '../config/config.json';

const getSubdomain = (): string => {
    const host = document.location.host;    
    const subdomain = host.split(".")[0];

    return subdomain;
}

// const getCampaign = (): string | undefined => {
//     const subdomain = getSubdomain();
//     const path = window.location.pathname;
//     if(path.indexOf(subdomain+'/') !== -1) {
//         const pathSplited = path.split('/'+subdomain+'/');
//         const campaignName = pathSplited[1].split('/')[0];
//         return campaignName;
//     } else {
//         return;
//     }
// }

const filesPath = (): string => config.serverURL;

const location = {
    subdomain: getSubdomain(),
    campaign: getSubdomain(),
    filesPath: filesPath()
}

export { getSubdomain, location };