export const values = {
  xs: 0, // phone
  sm: 600, // tablet
  md: 900, // small laptop
  lg: 1200, // desktop
  xl: 1536, // large screen
};

declare interface R {
  xl: [string];
  lg: [string];
  md: [string];
  sm: [string];
  xs: [string];
}

const up = <T extends keyof R, X>(size: T, content: X) => {
  return `
    @media(min-width: ${values[size]}px) {
      ${content}
    }
  `
};

const down = <T extends keyof R, X>(size: T, content: X) => {
  return `
    @media(max-width: ${values[size]}px) {
      ${content}
    }
  `
};

export const breakpoints = {
  up,
  down
}