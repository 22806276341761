import React from 'react';

import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { settings, settingsSlider } from './configs';

import {
  Section,
  Container,
  Accordion,
  AccordionSummary,
  Headline,
  Card,
  Slider,
  ContactForm,
  ButtonIcon,
  Banner,
  Grid,
  HowWorksCampaign,
} from '@design-system';

import { faq } from '@data/theme';

import {
  Main,
  MainSlider,
  SectionContact,
  FeaturedBox,
  ColumnBox,
  BoxTitle,
  BoxText,
  BoxSection,
  PartnerWarning
} from './styles';

import { Footer, Header } from '@components';

import { CampaignThemeProps } from '@interfaces/theme';

import { useThemeContext } from '@hooks';
import { location } from '@utils'
import { NotFound } from '../common/not-found';
import { useParams } from 'react-router-dom';

interface CampaignStateProps {
  isLoaded: boolean;
  theme: CampaignThemeProps | undefined;
}

const Campaign: React.FC = () => {

  const { subdomainStatus, loadCampaignData } = useThemeContext();

  const {campaignPath} = useParams();

  const [campaign, setCampaign] = React.useState<CampaignStateProps>({} as CampaignStateProps);
  const [partners, setPartners] = React.useState({});

  const settingsBannersSlider = {
    ...settingsSlider,
    nextArrow: <button><I icon={['far', 'chevron-right']} size="3x" /></button>,
    prevArrow: <button><I icon={['far', 'chevron-left']} size="3x" /></button>,
  };

  const sliderSettings = {
    ...settings,
    nextArrow: <button><I icon={['far', 'chevron-right']} size="2x" color={campaign.isLoaded && campaign.theme ? campaign.theme.section3.section3_styles.section3StylesSlider.section3StylesSliderArrowColor : '#CCCCCC'} /></button>,
    prevArrow: <button><I icon={['far', 'chevron-left']} size="2x" color={campaign.isLoaded && campaign.theme ? campaign.theme.section3.section3_styles.section3StylesSlider.section3StylesSliderArrowColor : '#CCCCCC'} /></button>,
  }

  const [isOpen, setIsOpen] = React.useState<number>(0);
  const [isOpenChildren, setIsOpenChildren] = React.useState<number>(0);


  const toggleOpen = (id: number) => () => {
    setIsOpen((isOpen: number) => isOpen === id ? 0 : id);
    setIsOpenChildren(prev => prev = 0);
  };

  const toggleOpenChildren = (id: number) => () => setIsOpenChildren(
    (isOpenChildren: number) => isOpenChildren === id ? 0 : id,
  );

  const campaignData = React.useCallback(async () => {

    setCampaign(prev => prev = {
      ...prev,
      isLoaded: false,
      theme: undefined
    })

    try {
      const response = await loadCampaignData({campaignName: campaignPath as string})
      if(response.error.status) {
        throw new Error('TCPG01 - Erro ao carregar o tema.')
      } else {
          setCampaign(prev => prev = {
              ...prev,
              isLoaded: true,
              theme: response.data
          })
      }
    } catch (error: any) {
      setCampaign(prev => prev = {
        ...prev,
        isLoaded: false,
        theme: undefined
      })
    }
  }, [])

  React.useEffect(() => {
    campaignData();
  }, [])

  return (
    <Main>
      <>
        {campaign.isLoaded && campaign.theme  ? 
        <>
            <Header
                theme={campaign.theme.themeName}
                className='headerCampaign'
                fontColor={'white'}
                activeMenuColorLink={''}
                backgroundColor={'red'}
                logo={`${campaign.theme.header.headerLogo}`}
                navData={campaign.theme.header.headerNav.headerNavData}
                buttonCreateAccount={campaign.theme.header.headerButtonCreateAccount}
                buttonAccess={campaign.theme.header.headerButtonAccess}
            />
            <>
              <Slider className='main-slider' {...settingsBannersSlider}>
                {campaign.theme.heroSlider.map(({ id, src, srcMobile, link }) => (
                  <Banner key={id} src={`${location.filesPath}${src}`} srcMobile={`${location.filesPath}${srcMobile}`} link={link} />
                ))}
              </Slider>
              <div id='como-participar' />
            </>

            <Section id='como-participar'>
              <Container>
                <Headline className='headlineColor'>{campaign.theme.section1.section1Headline.section1HeadlineText}</Headline>
                <Grid container>
                  {campaign.theme.section1.section1ComponentData.map(
                    ({ section1ComponentDataId, section1ComponentDataIconData, section1ComponentDataTitle, section1ComponentDataSubtitle }) => {
                      return (
                        <Grid key={section1ComponentDataId} item lg={4} md={12}>
                          <HowWorksCampaign
                            iconData={section1ComponentDataIconData as IconProp}
                            title={section1ComponentDataTitle}
                            subtitle={section1ComponentDataSubtitle} />
                        </Grid>
                      )
                    })}
                </Grid>
              </Container>
            </Section>

            <BoxSection className='boxSection'>
              <Section id='auth-section'>
                <Container>
                  <FeaturedBox className='left mr-b box1'>
                    <ColumnBox className='column info'>
                      <BoxTitle className='boxTitle'>
                        {campaign.theme.section2.section2_box1.section2Box1Title}</BoxTitle>
                      <BoxText className='boxText'>
                        {campaign.theme.section2.section2_box1.section2Box1Text}</BoxText>
                      <ButtonIcon
                        link={campaign.theme.section2.section2_box1.section2Box1Button.section2Box1ButtonLink}
                        text={campaign.theme.section2.section2_box1.section2Box1Button.section2Box1ButtonText}
                        iconSrc={<I icon={['far', 'long-arrow-right']}
                          size="lg"
                          className='icon'/>} />
                    </ColumnBox>
                    <ColumnBox className='column image'>
                      <div className="WrapAnchorPicture">
                        <div className="AnchorPicture">
                          <div className="WrapPicture">
                            <picture>
                              <img src={`${location.filesPath}${campaign.theme.section2.section2_box1.section2Box1Image}`} alt="Campanhas" />
                            </picture>
                          </div>
                        </div>
                      </div>
                    </ColumnBox>
                  </FeaturedBox>

                  <FeaturedBox className='right box2'>
                    <ColumnBox className='column info'>
                      <BoxTitle className='boxTitle'>
                        {campaign.theme.section2.section2_box2.section2_box2_title}</BoxTitle>
                      <BoxText className='boxText'>
                        {campaign.theme.section2.section2_box2.section2_box2_text}</BoxText>
                      <ButtonIcon
                        link={campaign.theme.section2.section2_box2.section2_box2_button.section2_box2_button_link}
                        text={campaign.theme.section2.section2_box2.section2_box2_button.section2_box2_button_text}
                        iconSrc={<I icon={['far', 'long-arrow-right']}
                          size="lg" 
                          className='icon'/>} />
                    </ColumnBox>
                    <ColumnBox className='column image'>

                      <div className="WrapAnchorPicture">
                        <div className="AnchorPicture">
                          <div className="WrapPicture">
                            <picture>
                              <img src={`${location.filesPath}${campaign.theme.section2.section2_box2.section2_box2_image}`} alt="Campanhas" />
                            </picture>
                          </div>
                        </div>
                      </div>
                    </ColumnBox>
                  </FeaturedBox>
                </Container>
              </Section>
            </BoxSection>

            <Section id='conheca-parceiros' sectionColor={campaign.theme.section3.section3_backgroundSectionColor}>
              <Container>
                <Headline className='headlineColor'>{campaign.theme.section3.section3_headline.section3HeadlineText}</Headline>
                <MainSlider>
                  {/* <Slider {...sliderSettings} className='campaign-slider'>
                    {campaign.theme.section3.section3_sectionSlider.map(
                      ({ section3SectionSliderId, section3SectionSliderImage, section3SectionSliderTitle, section3SectionSliderDescription }) => (
                        <Card
                          key={section3SectionSliderId}
                          image={`${location.filesPath}${section3SectionSliderImage}`}
                          title={section3SectionSliderTitle}
                          description={section3SectionSliderDescription}
                        />
                      ))}
                  </Slider> */}
                  <PartnerWarning>
                    <span>No momento não há catálogo disponível</span>
                  </PartnerWarning>
                </MainSlider>
              </Container>
              <div id='duvidas-frequentes' />
            </Section>
            <Section id='duvidas-frequentes-campaign'>
              <Container>
                <Headline className='headlineColor'>
                  {campaign.theme.faq.faqTitle.faqTitleText}
                </Headline>
                {faq.map(({ id, title, questions }, i) => {
                  const idx = i + 1;
                  return (
                    <Accordion
                      key={`${id}`}
                      title={title}
                      className="accordionCampaign"
                      onClick={toggleOpen(idx)}
                      isActive={isOpen === idx}
                    >
                      {questions.map(({ id, title, text }, i) => {
                        const index = i + 1;
                        return (
                          <AccordionSummary
                            key={`${id}`}
                            count={index}
                            title={title}
                            onClick={toggleOpenChildren(index)}
                            isActive={isOpenChildren === index}>
                            {text}
                          </AccordionSummary>
                        )
                      })}
                    </Accordion>
                  )
                })}
              </Container>
            </Section>

            <SectionContact sectionColor={'black'} id='fale-conosco'>
              <Section id='section-form'>
                <Container>
                  <ContactForm
                    backgroundColorButton={'black'}
                    fontColorButton={'white'}
                  />
                </Container>
              </Section>
            </SectionContact>

            <Footer
            logo={`${location.filesPath}${campaign.theme.footer.footerLogo}`}
            backgroundColor={campaign.theme.footer.footerBackgroundColor}
            fontColor={campaign.theme.footer.footerColor}
            navData={campaign.theme.footer.footerNav}
            theme={campaign.theme.themeName}/>
        </>
        :
          ''
        }
      </>
      <>
          {subdomainStatus && (<NotFound />)}
      </>
    </Main >
  );
}

export { Campaign };