import Service from './Service';

interface HotsiteDataProps {
    subdomain: string;
    fileLoad: string;
}

class Hotsite extends Service {

    listFiles({ subdomain, fileLoad }: HotsiteDataProps) {
        return this.axiosInstance.get(`/files/${subdomain}/${fileLoad}`);
    }

}

export default Hotsite;