import React from "react";
import { LogoIcon, NavItem, Grid, Container, Dialog, ContactForm, Regulament } from '@design-system';
import LogoCommarket from '@img/logo-commarket.png';
import { Main,  Nav, Ul, Informative, CompanyInfo, ContainerStyled } from './styles';
import { ModalStaticProps } from '@interfaces/dialog';
import { theme as t } from "@theme";


const dataFooter = [
    {
        title: 'Termos de uso',
        link: 'terms-of-use'
    },
    {
        title: 'Políticas de privacidade',
        link: 'privacy-policies'
    },
]

interface NavItemProps {
    title: string;
    link: string;
}

interface FooterProps {
    theme: string;
    logo: string;
    backgroundColor: string;
    fontColor: string;
    navData: NavItemProps[];
}

const Footer: React.FC<FooterProps> =
  ({theme, backgroundColor, fontColor, logo, navData}: FooterProps) => {
    
    const contactRef = React.useRef<ModalStaticProps>(null);
    const termsRef = React.useRef<ModalStaticProps>(null);

    const { color } = t;

    const handleDialog = (theme: string, key: string) => {

        if (theme === "campaign-theme") {

            switch (key) {

                case "regulamento":
                    termsRef.current?.show()
                    break;

                default:
                    (() => null)();
                    break;
            }
        }
        if (theme === "hotsite-theme") {

            switch (key) {
                case "fale-conosco":
                    contactRef.current?.show()
                    break;

                default:
                    (() => null)();
                    break;
            }
        }
    }
    
    return (
        <Main fontColor={fontColor} backgroundColor={backgroundColor}>
            <Container>
                <Grid container>
                    <Grid item lg={3} md={12}>
                        <LogoIcon className="on-footer" src={logo} link={""} />
                    </Grid>
                    <Grid item lg={3} md={12}>
                        <Informative className="on-footer">
                            Consulte condições de participação, datas dos sorteios e regulamento completo aqui no site. Imagens ilustrativas.
                        </Informative>
                    </Grid>
                    <Grid item lg={3} md={12}>
                        <Nav>
                            <Ul>
                                {navData.map(({ title, link }, i) => {
                                    const titleLower = title.replace(" ", "-").toLowerCase();
                                        return (
                                            <NavItem
                                                key={`${title}-${i}`}
                                                title={title}
                                                link={link}
                                                onFooter
                                                isActive={false}
                                                action={() => handleDialog(theme, titleLower)}
                                            />
                                        )
                                    }
                                )}
                            </Ul>
                        </Nav>
                    </Grid>
                    <Grid item lg={3} md={12}>
                        <Nav>
                            <Ul>
                                {dataFooter.map(({ title, link }, i) => (
                                    <NavItem
                                        key={`${title}-${i}`}
                                        title={title}
                                        link={link}
                                        onFooter
                                        isActive={false}
                                    />
                                ))}
                            </Ul>
                        </Nav>
                    </Grid>
                </Grid>
            </Container>
            <CompanyInfo>
                <ContainerStyled>
                    <Informative>
                        <span>© 2022 Campanhas online</span>
                    </Informative>
                    <Informative>
                        <span>Desenvolvido por </span>
                        <img src={LogoCommarket} alt="commarket" />
                    </Informative>
                </ContainerStyled>
            </CompanyInfo>

            <>
                <Dialog ref={contactRef} desactivateLink={() => null}>
                    <ContactForm
                        backgroundColorButton={color.indigo[700]} fontColorButton={color.common.white}
                    />
                </Dialog>

                <Dialog ref={termsRef} title="Regulamento" desactivateLink={() => null}>
                    <Regulament />
                </Dialog>
            </>
        </Main>
    )
}

export { Footer };
