import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Main, Columns, Column, SidebarContentForm } from './styles';

import { Topbar, Sidebar, SidebarHead, Browser, ColorPicker, SelectColor, UploadField, UploadPreview } from './components';

import { debounce } from "lodash";

import { useStorageTheme } from '@hooks';

import { ScreenSize, Inputs, SectionEditProps } from './interfaces';

import { hotsiteTheme } from '@data/theme';
import { HotsiteThemeProps, SliderProps } from '@interfaces/theme';

import { JSONFields } from './data';

import { recursiveFindInObject } from '@utils';

import ImageUploadPlaceholder from '@img/upload-image-placeholder.png'

const Customization: React.FC = () => {

    const { fields } = JSONFields();

    const { theme, saveOnStorageTheme } = useStorageTheme();

    // * Refs
    const iframeRef = React.useRef<HTMLIFrameElement>(null);
    const refColor = React.useRef<SectionEditProps>();
    const debounceSave = React.useRef(debounce((value) => saveOnStorageTheme(value), 1000)).current;
    const debounceSaveColor = React.useRef(debounce(({ cor, inputName }: SectionEditProps) => setColor(prev => prev = { ...prev, cor: cor, inputName: inputName }), 300)).current;

    // * States
    const [color, setColor] = React.useState<SectionEditProps>({ cor: '#ccc', inputName: '' });
    const [screenSize, setScreenSize] = React.useState<ScreenSize>('desktop');
    const [isOpen, setIsOpen] = React.useState<number>(0);
    const [input] = React.useState<Inputs[]>(fields as Inputs[]);
    const [sectionID, setSectionID] = React.useState<string>('');
    const [parserTheme, setParserTheme] = React.useState<HotsiteThemeProps>({} as HotsiteThemeProps);
    const [sliderItemLink, setSliderItemLink] = React.useState<string>('');
    const [imageFileURL, setImageFileURL] = React.useState<string>('');
    const [slider, setSlider] = React.useState<SliderProps[]>(Object.keys(theme).length ? theme.heroSlider : []);

    React.useEffect(() => {
        refColor.current = color;
    });

    const prevColorValue = refColor.current ?? color;

    const defaultTheme = React.useMemo(() => (hotsiteTheme), []);

    const setThemelocalStorage = React.useCallback(() => {
        const verifyTheme = Object.keys(theme).length > 0;
        if (verifyTheme) {
            return setParserTheme(theme);
        } else {
            return saveOnStorageTheme(defaultTheme);
        }

    }, [defaultTheme, theme, saveOnStorageTheme]);

    const handleSectionID = (idx: number) => (id: string) => {
        setIsOpen((isOpen: number) => isOpen === idx ? 0 : idx)
        setSectionID(prev => prev === id ? '' : id);
    };

    const handleColorPicker = (value: string, name: string) => debounceSaveColor({ cor: value, inputName: name });

    React.useEffect(() => {
        if (prevColorValue.cor !== color.cor) {
            applyEditCurrent(color.inputName, sectionID, color.cor);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [color]);

    const applyEditCurrent = (name: string, section_id: string, value: string) => {

        recursiveFindInObject(parserTheme[section_id as keyof typeof parserTheme], name)[name] = value;

        return debounceSave(parserTheme);
    }

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>, id?: string, idx?: number) => {

        const { files, name } = event.target;

        if (files) {
            const fileURL = URL.createObjectURL(files[0]);

            if (name === "input-desktop") {
                if (slider.length !== 3) {
                    setSlider(prev => prev = [...prev,
                    {
                        id: uuidv4(),
                        link: '#!',
                        src: fileURL,
                        order: idx ? idx : 1,
                        srcMobile: fileURL
                    }
                    ])
                }
            }

            if (name === "input-desktop-change") {

                handleEditSlide({ id: id, src: fileURL });
            }

            if (name === "input-mobile") {

                handleEditSlide({ id: id, srcMobile: fileURL });
            }

            if (name === "headerLogo") {
                setImageFileURL(prev => prev = fileURL);
                applyEditCurrent(name, sectionID, fileURL);
            }
        }

    }

    const deleteSlide = (id: string) => {
        const findSlide = slider.filter((item) => item.id !== id);

        setSlider(prev => prev = findSlide);
    }

    const deleteMobileSlide = (id: string) => {

        const findSlide = slider.find((item) => item.id === id);
        handleEditSlide({ id: id, srcMobile: findSlide?.src })
    }

    const handleEditSlide = ({ id, link, src, srcMobile }: Partial<SliderProps>) => {

        const map = slider.map((item) => {

            if (item.id === id) {
                return {
                    ...item,
                    id,
                    link: link ? link : item.link,
                    src: src ? src : item.src,
                    srcMobile: srcMobile ? srcMobile : item.srcMobile,
                }
            }

            return item;

        });

        setSlider(prev => prev = map);
    }

    React.useEffect(() => {
        if (slider.length > 0) {
            const toSave = Object.assign(theme, { heroSlider: slider });
            debounceSave(toSave);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slider]);

    React.useEffect(() => {
        setThemelocalStorage()
    }, [setThemelocalStorage]);

    return (
        <Main>
            <Topbar
                actionHandleBack={() => console.log("Go Back!!")}
                actionHandlePublish={() => console.log("Publish!!")}
                actionHandleChangeScreen={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const { value } = e.target;
                    return setScreenSize(prev => prev = value as ScreenSize);
                }}
            />
            <Columns>
                <Column>
                    <Sidebar>

                        {input.map(({ id, name, child, sectionID }, i) => (
                            <SidebarHead
                                key={`${id}-${name}`}
                                title={name}
                                active={isOpen === i + 1}
                                onClick={() => handleSectionID(i + 1)(sectionID)}
                                className={i + 1 === isOpen ? 'active-head' : 'disabled-head'}
                            >
                                <SidebarContentForm style={{ display: `${i + 1 === isOpen ? 'block' : 'none'}` }}>

                                    {child.map(({ title, fields }) => (
                                        <>
                                            <h6>{title}</h6>
                                            {
                                                fields.map(({ id, label, name, value, type }, i) => (
                                                    <React.Fragment key={`${id}-${name}`}>

                                                        {sectionID === "heroSlider"
                                                            && slider.length !== 3 ?
                                                            <UploadField
                                                                name="input-desktop"
                                                                id={`${id}-${i}`}
                                                                label={label}
                                                                onChange={(event) => handleFileUpload(event, id, i)}
                                                                previewSrc={ImageUploadPlaceholder}
                                                            />
                                                            : null
                                                        }

                                                        {sectionID === "heroSlider"
                                                            && slider.map(({ id, src, srcMobile }, idx) => (
                                                                <>
                                                                    <UploadPreview
                                                                        title={`Slider-${idx + 1}`}
                                                                        previewSrc={src}
                                                                        mobilePreviewSrc={srcMobile}
                                                                        deleteDesktopSlide={() => deleteSlide(id)}
                                                                        onChange={
                                                                            (event) => setSliderItemLink(event.target.value)}
                                                                        onChangeDesktop={(event) => handleFileUpload(event, id)}
                                                                        saveLink={
                                                                            () => handleEditSlide(
                                                                                { id: id, link: sliderItemLink }
                                                                            )
                                                                        }
                                                                        onChangeMobile={(event) => handleFileUpload(event, id)}
                                                                        deleteMobileSlide={() => deleteMobileSlide(id)}
                                                                        deskId={`desktop-image-${id}`}
                                                                        moblId={`mobile-image-${id}`}
                                                                    />
                                                                </>
                                                            ))

                                                        }

                                                        {fields
                                                            && type === "color"
                                                            && (<SelectColor id={id} label={label}>
                                                                <ColorPicker
                                                                    color={value}
                                                                    setColor={(value: string) => handleColorPicker(value, name)}
                                                                />
                                                            </SelectColor>)
                                                        }

                                                        {type === "file"
                                                            && (
                                                                <UploadField
                                                                    name={name}
                                                                    id={id}
                                                                    label={label}
                                                                    onChange={handleFileUpload}
                                                                    previewSrc={imageFileURL ? imageFileURL : value}
                                                                />
                                                            )}
                                                    </React.Fragment>
                                                ))
                                            }

                                        </>

                                    ))}
                                </SidebarContentForm>

                            </SidebarHead>
                        ))}

                    </Sidebar>
                </Column>
                <Column>
                    <Browser urlAddress='url' screenWidth={screenSize}>

                        <iframe
                            src={'/'}
                            ref={iframeRef}
                            id="render-hotsite"
                            width='100%'
                            height="100%"
                            title='testing'
                            loading="lazy"
                        />

                    </Browser>
                </Column>
            </Columns>

        </Main>
    )
}

export { Customization };