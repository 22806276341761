import styled from 'styled-components';

import { theme } from '@theme';

const { color } = theme;

export const Main = styled.div`
    border-radius: 1px solid red;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    & > label {
        color: ${color.grey[700]};
        font-size: .8125rem;
        margin-bottom: .3125rem;
        display: block;
    }
`;

export const WrapperChildren = styled.div`
   position: absolute;
   right: 0;
   top: -.4375rem;

   & > input {
    width: 100%;
   }
`;