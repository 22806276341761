const typography = {
    htmlFontSize: '1rem',
    fontSize: '.875rem',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
}

export { typography }