import React from 'react';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { Main, Head, Title, ToggleIcon, Columns, Column } from './styles';


interface AccordionProps {
    title: string;
    isActive: boolean;
    onClick: () => void;
    className: string;
    children: React.ReactNode;
}

const Accordion: React.FC<AccordionProps> = ({ title, isActive, onClick, className, children }: AccordionProps) => {

    return (
        <Main className={className}>
            <Head onClick={onClick} className="headAccordion">
                <Columns className="columns">
                    <Column>
                        <Title>{title}</Title>
                    </Column>
                    <Column className="columnIcon">
                        <ToggleIcon className="toggleDiv">
                            <I icon={
                                !isActive
                                    ? ['fas', 'plus-circle']
                                    : ['fas', 'minus-circle']
                            } style={{ height: '1.625rem' }}
                            className="toggleIcon"/>
                        </ToggleIcon>
                    </Column>
                </Columns>
            </Head>
            {isActive && (children)}
        </Main>
    )
}

export { Accordion };