import React from "react";

import {Main, HeaderBox} from './styles';

interface HowWorksBoxProps {
    step: number;
    children: React.ReactNode;
}

const HowWorksBox: React.FC<HowWorksBoxProps> =
  ({ step, children}: HowWorksBoxProps) => {
    return (
        <Main className="mainBox">
            <HeaderBox className="headerBox">
                {step}
            </HeaderBox>
            {children}
        </Main>
    )
}

export {HowWorksBox};