import React from 'react';

interface GridProps {
  xl?: number;
  lg?: number;
  md?: number;
  sm?: number;
  xs?: number;
  container?: boolean;
  item?: boolean;
  children: React.ReactNode;
}

const Grid: React.FC<GridProps> = (
  {
    xl,
    lg,
    md,
    sm,
    xs,
    container,
    item,
    children
  }: GridProps
) => {

  return (
    <>
      {container && (
        <div className="bootstrap-wrapper">
          <div className="container max-w-fluid">
            <div className="row">
              {children}
            </div>
          </div>
        </div>
      )}

      {item && (
        <div className={`
        ${xs ? `col-xs-${xs} ` : ''}
        ${sm ? `col-sm-${sm} ` : ''}
        ${md ? `col-md-${md} ` : ''}
        ${lg ? `col-lg-${lg} ` : ''}
        ${xl ? `col-xl-${xl} ` : ''}
        `}>
          {children}
        </div>
      )}
    </>
  )
}

export { Grid };