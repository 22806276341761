import React from 'react';
import { HexColorPicker, HexColorInput } from 'react-colorful';

import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { faTint } from '@fortawesome/pro-solid-svg-icons';

import { debounce } from "lodash";
import { Main, WrapperColorPicker, Head, Title, ButtonToggle, InputColor, ButtonColor } from './styles';
import { useClickOutside } from '@utils';
import { theme } from '@theme';

interface ColorPickerProps {
    color: string;
    setColor: any;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ color, setColor }: ColorPickerProps) => {

    const debounceSave = React.useRef(debounce((val) => setColor(val), 300)).current;

    const handleColorPicker = (value: string) => debounceSave(value);

    const [open, setOpen] = React.useState<boolean>(false);

    const handleOpen = () => setOpen(prev => !prev);

    const popover = React.useRef(null);

    const close = React.useCallback(() => setOpen(false), []);
    useClickOutside(popover, close);
    

    return (
        <Main>
            <ButtonColor type='button' onClick={handleOpen} backgroundColor={color} />

            {open && (
                <WrapperColorPicker ref={popover}>

                    <Head>
                        <Title>Color Picker</Title>
                        <ButtonToggle type="button" onClick={handleOpen}>
                            <I icon={faTimes as any} color={theme.color.grey[700]} size="lg" />
                        </ButtonToggle>
                    </Head>

                    <HexColorPicker color={color} onChange={handleColorPicker} className='color-picker' />

                    <InputColor>

                        <label htmlFor="set-color">
                            <span className='title'>HEX</span>
                            <div className='wrap'>
                                <span className='icon'>
                                    <I icon={faTint as any} size="sm" color={color} />
                                </span>
                                {/* <input
                                    type="text"
                                    name="select_color"
                                    id='set-color'
                                    value={color}
                                    onChange={(event) => handleColorPicker(event.target.value)}
                                /> */}
                                <HexColorInput color={color} onChange={handleColorPicker} />
                            </div>
                        </label>
                    </InputColor>

                </WrapperColorPicker>
            )}
        </Main>
    )
}

export { ColorPicker };