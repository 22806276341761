import { theme } from '@theme';
import styled from 'styled-components';

const { border, typography } = theme;

interface LinkProps {
}

export const Main = styled('li')`
    padding: 10px 20px;

    &.footer {
        font-weight: 500;
        padding: 5px 0;
    }

`;

export const NavLink = styled.span`
    font-size: 16px;
    
    &.active {
        font-weight: ${typography.fontWeightBold};
        &::after {
            content: '';
            display: block;
            height: .1875rem;
            width: 100%;
            position: relative;
            top: .125rem;
            border-radius: ${border.round};
        }
    }

    &.footer{
        color: ${theme.color.grey[900]};
        font-size: 14px;
    }
`;