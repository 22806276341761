import React from 'react';

import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faDesktop, faMobile } from '@fortawesome/pro-regular-svg-icons';

import { Main, ButtonBack, Columns, Column, ButtonSwitchResl } from './styles';

interface TopbarProps {
    actionHandleBack: () => void;
    actionHandlePublish: () => void;
    actionHandleChangeScreen: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const Topbar: React.FC<TopbarProps> = (
    { actionHandleBack, actionHandleChangeScreen, actionHandlePublish }: TopbarProps
) => {
    return (
        <Main>
            <Columns>
                <Column>
                    <ButtonBack onClick={actionHandleBack}>
                        <I icon={faChevronLeft as any} />
                        <span>Voltar</span>
                    </ButtonBack>
                </Column>
                <Column>
                    <ButtonSwitchResl>
                        <label htmlFor="change-to-desk">
                            <input type="radio" name="change_screen" id="change-to-desk" value='desktop' onChange={actionHandleChangeScreen} defaultChecked />
                            <I icon={faDesktop as any} size="lg" />
                            <span className='mark'></span>
                        </label>
                    </ButtonSwitchResl>
                    <ButtonSwitchResl>
                        <label htmlFor="change-to-mobl">
                            <input type="radio" name="change_screen" id="change-to-mobl" value='mobile' onChange={actionHandleChangeScreen} />
                            <I icon={faMobile as any} size="lg" />
                            <span className='mark'></span>
                        </label>
                    </ButtonSwitchResl>
                </Column>
                <Column>
                    <button type='button' onClick={actionHandlePublish}>Publicar</button>
                </Column>
            </Columns>
        </Main>
    )
}

export { Topbar };