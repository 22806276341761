export function maxText({value, maxChars}: {value: string, maxChars: number}) {

  if(!!value) {
    if (value.length >= maxChars) {
      return value.substring(0, maxChars) + "...";
    } else {
      return value;
    }
  } else {
    return ''; 
  }
}
