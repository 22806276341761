import React from "react";
import {Main, WarningTitle, WarningSubtitle} from './styles';
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

const NotFound = () => {

    return (
        <Main>
            <I
                className="warning-icon"
                icon={['fal', 'exclamation-triangle']}
                style={{ height: '6.5rem' }}/>
            
            <WarningTitle>
                Ops... Página não encontrada!
            </WarningTitle>
            <WarningSubtitle>
                O endereço da página mudou ou a página não existe.
            </WarningSubtitle>
        </Main>
    )
}

export {NotFound};