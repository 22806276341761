import styled from "styled-components";
import {theme} from '@theme';

export const Main = styled('div')`
    padding: 8rem 0 12rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    & > .warning-icon {
        color: ${theme.color.grey[800]};
    }
`;

export const WarningTitle = styled('h1')`
    font-size: 1.8rem;
    font-weight: 700;
    margin-top: 1.5625rem;
    color: ${theme.color.grey[800]};
`;

export const WarningSubtitle = styled('span')`
    font-size: 1rem;
    font-weight: 400;
    margin-top: 0.625rem;
    margin-bottom: 0.9375rem;
    color: ${theme.color.grey[600]};
`;