
import axios, { AxiosInstance } from "axios";
import api from '@config/config.json';

class Service {
    axiosInstance: AxiosInstance;

    constructor() {

        this.axiosInstance = axios.create({
            baseURL: api.serverURL,
            withCredentials: false
        });
    }


}

export default Service