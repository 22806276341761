interface SvgProps {
    fill?: string;
    height: number
}

export const svg = ({ fill, height }: SvgProps) => `
    & > .svg {
        line-height: 0;
        div {
            svg {
                cursor: pointer;
                height: ${height}px !important;
                width: auto;
                ${fill && `
                    
                    fill: ${fill ? fill : 'currentColor'}
                    
                    `
                }
            }
        }
    }
`;