import styled from 'styled-components';
import { theme } from '@theme';

const { breakpoints } = theme;

interface SectionProps {
  color: string;
}


export const Main = styled.section<SectionProps>`
  padding: 3.75rem 0;
  background-color: ${props => props.color};
  
  ${breakpoints.down('md', `
    padding: 2.5rem 0;
  `)}
`;
