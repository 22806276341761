import React, { useState } from "react";

import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { Button, Container, LogoIcon, NavItem, Dialog, Regulament, ContactForm } from '@design-system';

import { Main, Columns, Column, Nav, Ul, HeaderSvg } from './styles';
import { HeaderProps } from "./interfaces";

import { location } from '@utils'

import { ModalStaticProps } from '@interfaces/dialog';
import { theme as t } from "@theme";
import { useLocation } from "react-router-dom";
import { useThemeContext } from "@hooks";

const Header: React.FC<HeaderProps> =
    ({
        logo,
        className,
        navData,
        buttonCreateAccount,
        buttonAccess,
        theme,
    }: HeaderProps) => {

        const { color } = t;

        const { pathname } = useLocation();

        const { hotsite } = useThemeContext();

        const contactRef = React.useRef<ModalStaticProps>(null);
        const termsRef = React.useRef<ModalStaticProps>(null);

        const [navVisibility, setNavVisibility] = useState<boolean>(false);
        const [activeLink, setActiveLink] = React.useState<number>(0);

        const handleMenu = () => {
            setNavVisibility((prev) => !prev);

            if (!navVisibility) {
                document.body.classList.add('overflow-h');
            } else {
                document.body.classList.remove('overflow-h');
            }
        }

        const handleDialog = (theme: string, key: string) => {

            if (theme === "campaign-theme") {

                switch (key) {

                    case "regulamento":
                        termsRef.current?.show()
                        break;

                    default:
                        (() => null)();
                        break;
                }
            }
            if (theme === "hotsite-theme") {

                switch (key) {
                    case "fale-conosco":
                        contactRef.current?.show()
                        break;

                    default:
                        (() => null)();
                        break;
                }
            }
        }

        const toggleActiveLink = (id: number) => () => {
            setActiveLink((isOpen: number) => isOpen === id ? 0 : id);
            handleMenu();
        };

        const handleActiveLink = React.useCallback(() => {
            setActiveLink((isOpen: number) => isOpen = 0);
        }, []);

        React.useEffect(() => {
            handleActiveLink();
        }, [pathname, handleActiveLink]);

        React.useEffect(() => {
            return () => {
                document.body.classList.remove('overflow-h');
            }
        }, []);

        React.useEffect(() => {
            $(`#sticker`).sticky({ topSpacing: 0, zIndex: '999' });

            $('#sticker').on('sticky-start', () => $("#sticker").addClass('sticker-active'));

            $('#sticker').on('sticky-end', () => $("#sticker").removeClass('sticker-active'));

        }, []);

        return (
            <React.Fragment>
                {hotsite.isLoaded ? (
                    <Main className={className} id="sticker">
                        <Container className="wrapper">
                            <Columns className="columns">
                                <Column className="header-control">
                                    <LogoIcon src={`${location.filesPath}${logo}`} link={"/"} />
                                    <HeaderSvg onClick={handleMenu}>
                                        <I icon={
                                            navVisibility
                                                ? ['far', 'times']
                                                : ['fas', 'bars']
                                        } size="lg" />
                                    </HeaderSvg>
                                </Column>

                                <Column className={`second-column ${navVisibility ? 'menu-mobile' : ''}`}>
                                    <Nav className="navHeader">
                                        <Ul className="ul">
                                            {navData.map(({ id, title, link, action }, i) => {

                                                const titleLower = title.replace(" ", "-").toLowerCase();

                                                return (
                                                    <NavItem
                                                        key={`${title}-${i}`}
                                                        onClick={toggleActiveLink(Number(id))}
                                                        title={title}
                                                        link={link}
                                                        isActive={activeLink === i + 1}
                                                        action={() => handleDialog(theme, titleLower)}
                                                    />
                                                )
                                            })}
                                        </Ul>
                                    </Nav>
                                    {buttonCreateAccount && buttonAccess ? 
                                    <>
                                        <Button
                                            className="buttonAuth"
                                            withBorder={true}
                                            link={'#!'}
                                            text={buttonCreateAccount.headerButtonCreateAccountText}
                                            onClick={() => {
                                                window.open(buttonCreateAccount.headerButtonCreateAccountLink, '_blank')
                                                handleMenu();
                                            }}
                                        />
                                        <Button
                                            className="buttonAccess"
                                            withBorder={true}
                                            link={buttonAccess.headerButtonAccessLink}
                                            text={buttonAccess.headerButtonAccessText}
                                            onClick={() => {
                                                window.open(buttonAccess.headerButtonAccessLink, '_blank')
                                                handleMenu();
                                            }}
                                        />
                                    </>
                                    :
                                    null
                                    }
                                </Column>

                            </Columns>
                        </Container>
                    </Main>

                ) : ''}

                <Dialog ref={contactRef} desactivateLink={() => setActiveLink(0)}>
                    <ContactForm
                        backgroundColorButton={color.indigo[700]} fontColorButton={color.common.white}
                    />
                </Dialog>

                <Dialog ref={termsRef} title="Regulamento" desactivateLink={() => setActiveLink(0)}>
                    <Regulament />
                </Dialog>
            </React.Fragment>
        )
    }

export { Header };
