import styled from 'styled-components';
import { theme } from '@theme';

const { color, shadow, border, typography, zIndex } = theme;

interface ButtonColorProps {
    backgroundColor: string;
}

export const Main = styled.div``;

export const Head = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: .5rem;
`;

export const Title = styled.div`
    font-size: .8125rem;
    font-weight: ${typography.fontWeightMedium};
    color: ${color.grey[800]};
    text-transform: capitalize;
`;

export const ButtonToggle = styled.button`
    padding: 0;
    border: 0;
    height: 30px;
    width: 30px;
    cursor: pointer;
    background-color: transparent;
`;

export const ButtonColor = styled.button<ButtonColorProps>`
    padding: 0;
    border: 0;
    height: 20px;
    width: 30px;
    cursor: pointer;
    background-color: ${({ backgroundColor }) => backgroundColor ? backgroundColor : theme.color.common.black};
    border: 2px solid ${color.common.white};
    box-shadow: ${shadow.default};
    border-radius: ${border.input};
`;

export const WrapperColorPicker = styled.div`

    background-color: ${color.common.white};
    box-shadow: ${shadow.default};
    padding: .5rem .625rem .625rem .625rem;
    margin: .3125rem;
    border-radius: ${border.default};
    border: .0625rem solid ${color.grey[50]};
    width: 240px;
    position: absolute;
    z-index: ${zIndex.max};
  
  & > .react-colorful.color-picker {
    width: 100%;

    & > .react-colorful__saturation {
        border-top-left-radius: ${border.input};
        border-top-right-radius: ${border.input};
        & > .react-colorful__interactive {

            & > .react-colorful__pointer.react-colorful__saturation-pointer {
                height: .9375rem;
                width: .9375rem;
            }
        }
    }

    & > .react-colorful__hue.react-colorful__last-control {
        border-bottom-left-radius: ${border.input};
        border-bottom-right-radius: ${border.input};
        height: 14px;
        
        & > .react-colorful__interactive {
            
            .react-colorful__pointer.react-colorful__hue-pointer {
                border-radius: 0;
                height: 1.25rem;
                width: .75rem;
            }
        }
    }
  }
`;

export const InputColor = styled.div`
margin-top: .625rem;
& > label {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    & > .title {
        font-size: .75rem;
        font-weight: ${typography.fontWeightMedium};
        color: ${color.blueGrey[700]};
        text-transform: uppercase;
    }
    
    & > .wrap {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-left: .625rem;
        
        & > .icon {
            height: 2rem;
            width: 2rem;
            min-width: 2rem;
            background-color: ${color.blueGrey[50]};
            display: flex;
            align-items: center;
            justify-content: center;
            border-top-left-radius: ${border.input};
            border-bottom-left-radius: ${border.input};
        }

        & > input {
            width: 100%;
            height: 2rem;
            border-top-right-radius: ${border.input};
            border-bottom-right-radius: ${border.input};
            border: 0;
            background-color: ${color.blueGrey[50]};
            font-size: .8125rem;
            font-weight: ${typography.fontWeightMedium};
        }
    }
  }
`;
