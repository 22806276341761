import React from 'react';

import MainIcon from '@img/mail-icon.png';

import { useForm, SubmitHandler } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';

import $ from "jquery";
import "jquery-mask-plugin";

import {
  Grid,
} from '@design-system';

import {
  BoxContact,
  ContactHeadline,
  ContactSubTitle,
  Form,
  FormGroup,
  WrapSubmit,
  SendButton,
  HelperText,
} from './styles';

export const formSchema = Yup.object().shape({
  name: Yup.string()
    .required('Digite seu nome'),
  email: Yup.string().email('Digite um email válido')
    .required('Digite seu e-mail'),
  phone: Yup.string()
    .required('Digite sua número de telefone'),
  message: Yup.string()
    .required('Digite uma mensagem'),

});

export interface FormInputs {
  name: string;
  email: string;
  phone: string;
  message: string;
}

interface ContactFormProps {
  backgroundColorButton: string;
  fontColorButton: string;
}

const ContactForm: React.FC<ContactFormProps> =
  ({backgroundColorButton, fontColorButton}: ContactFormProps) => {

  const { register, handleSubmit, formState: { errors } } = useForm<FormInputs>({
    resolver: yupResolver(formSchema),
    mode: "onBlur",
  });

  const onSubmit: SubmitHandler<FormInputs> = async (data) => console.log(data);

  React.useEffect(() => {
    $(".phone_with_ddd").mask("(00) 00000-0000");
  }, []);

  return (
    <React.Fragment>
            <BoxContact className='boxForm'>
              <ContactHeadline>
                Dúvidas? Fale conosco <img src={MainIcon} alt="fale conosco icon" />
              </ContactHeadline>
              <ContactSubTitle>
                Envie-nos uma mensagem e te retornaremos o mais breve possível!
              </ContactSubTitle>

              <Form className='contactForm' onSubmit={handleSubmit(onSubmit)}>
                <Grid container>
                  <Grid item md={6}>
                    <FormGroup>
                      <input type='text' placeholder="Nome" {...register("name", { required: true })} />
                      {errors.name && (
                        <HelperText error>{errors.name.message}</HelperText>
                      )}
                    </FormGroup>
                  </Grid>
                  <Grid item md={6}>
                    <FormGroup>
                      <input type='email' placeholder="Email"  {...register("email", { required: true })} />
                      {errors.email && (
                        <HelperText error>{errors.email.message}</HelperText>
                      )}
                    </FormGroup>
                  </Grid>
                  <Grid item md={12}>
                    <FormGroup>
                      <input type='text' className='phone_with_ddd' placeholder="Telefone" {...register("phone", { required: true })} />
                      {errors.phone && (
                        <HelperText error>{errors.phone.message}</HelperText>
                      )}
                    </FormGroup>
                  </Grid>
                  <Grid item md={12}>
                    <FormGroup>
                      <textarea placeholder="Mensagem" {...register("message", { required: true })} />
                      {errors.message && (
                        <HelperText error>{errors.message.message}</HelperText>
                      )}
                    </FormGroup>
                  </Grid>
                  <Grid item md={12}>
                    <WrapSubmit className='buttonWrap'>
                      <SendButton className='sendButton' backgroundColor={backgroundColorButton} color={fontColorButton} type='submit'>
                        Enviar
                      </SendButton>
                    </WrapSubmit>
                  </Grid>
                </Grid>
              </Form>
            </BoxContact>

    </React.Fragment >
  );
}

export { ContactForm };