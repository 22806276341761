import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {theme} from '@theme';

export const LinkStyled = styled(Link)`

    &.on-footer {
        ${theme.breakpoints.down('md',
            `
            width: 100%;
            display: flex;
            justify-content: center;
            `
        )}
    }
`;

export const Image = styled('img')`
    width: auto;
    max-height: 52px;
    max-width: 150px;
`;
