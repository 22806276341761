import styled from "styled-components";
import { theme } from "@theme";

const { breakpoints } = theme;

export const Main = styled.div`
    &.WrapAnchorPicture {
        height: 100vh;
        max-height: 520px;
        
        ${breakpoints.down('md', `
            max-height: 400px;
        `)}

        & > .AnchorPicture {
            display: block;
            height: 100%;
            width: 100%;
            overflow: hidden;
            
            & > .WrapPicture {
                height: 100%;
                width: 100%;
                picture {
                    display: contents;
                    
                    source,
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        border: 0;
                        max-width: 100%;
                        vertical-align: middle;
                        display: table-caption !important;
                    }
                }
            }
        }
    }
`;