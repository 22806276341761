import styled from 'styled-components';

import { theme } from '@theme';

interface HeadProps {
    active: boolean;
}

const { color, typography } = theme;

export const Main = styled.div`
    border-radius: 1px solid red;
    width: 260px;
    height: 100%;
    border-right: .0625rem solid ${color.grey[200]};
    background-color: ${color.grey[200]};
    /* overflow: hidden; */
    /* overflow-y: auto; */
    position: relative;

`;

export const Head = styled.div<HeadProps>`
    cursor: pointer;
    padding: 1rem 1.5625rem;
    background-color: ${color.common.white};
    border-bottom: .0625rem solid ${color.grey[200]};
    align-items: center;
    justify-content: space-between;
    width: 100%;
    display: flex;

    ${({ active }) => active && `
        flex-direction: row-reverse;
        justify-content: flex-end;
    `}

    & > span {
        font-weight: ${typography.fontWeightBold};
        color: ${color.grey[900]};
        text-transform: capitalize;
        font-size: 1rem;
        display: block;
        ${({ active }) => active && `
            margin-left: 1.875rem;
        `}
    }
`;

export const Content = styled.div`
    /* padding: 1.875rem 1.5625rem; */
    height: auto;
    
`;

export const SidebarContent = styled.div<HeadProps>`
    display: block;
    position: relative;
    &.active-head {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        z-index: 999;
    }

    &.disabled-head {
        display: block;
    }
`;