import React from 'react';

interface ContainerProps {
    className?: string;
    children: React.ReactNode;
}

const Container: React.FC<ContainerProps> = ({ className, children }: ContainerProps) => {
    return (
        <div className={`bootstrap-wrapper ${className ? className : ''}`}>
            <div className="container">
                {children}
            </div>
        </div>
    )
}

export { Container };