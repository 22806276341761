import React from "react";
import {HowWorksBoxCampaign} from '@design-system';
import { IconProp } from "@fortawesome/fontawesome-svg-core";

import {Main, Title, Subtitle} from './styles';

interface HowWorksCampaignProps {
    iconData: IconProp;
    title: string;
    subtitle: string;
}

const HowWorksCampaign =
  ({iconData, title, subtitle}: HowWorksCampaignProps) => {
    return (
        <HowWorksBoxCampaign
          iconData={iconData}>
            <Main className="HowWorksCampaign">
                <Title>
                    {title}
                </Title>
                <Subtitle>
                    {subtitle}
                </Subtitle>
            </Main>
        </HowWorksBoxCampaign>
    )
}

export {HowWorksCampaign};
