import styled from 'styled-components';

import { theme } from '@theme';

const { color, typography } = theme;

interface MainProps {
    screenWidth: string;
}

type ScreenSize = "desktop" | "mobile";

interface ScreenSizes {

    ScreenSize: ScreenSize
}

export const Main = styled.div<MainProps>`
    width: ${({ screenWidth }) => screenWidth ? screenWidth : `100%`};
    height: calc(100% - 95px);
    border: .0625rem solid ${color.grey[200]};
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: ${color.common.white};
    overflow: hidden;
    -webkit-transition: width 0.35s ease-in-out;
    -moz-transition: width 0.35s ease-in-out;
    -ms-transition: width 0.35s ease-in-out;
    -o-transition: width 0.35s ease-in-out;
    transition: width 0.35s ease-in-out;
`;

export const Head = styled.div<ScreenSizes>`
    padding: 0 .9375rem;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    background-color: ${color.common.white};
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: ${({ ScreenSize }) => (ScreenSize === "desktop" ? "row" : "row-reverse")};
    border-bottom: .0625rem solid ${color.grey[100]};
    
    & > span {
        font-weight: ${typography.fontWeightBold};
        color: ${color.grey[900]};
    }
`;

export const BrowserActions = styled.div<ScreenSizes>`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: .9375rem;
    
    &:first-child {
        ${({ ScreenSize }) => (ScreenSize === "desktop" 
        ? `
            padding-left: 0;
        ` 
        : `
            padding-right: 0;
        `)};
    }
    
    & > svg {
        margin-left: .625rem;
        width: ${({ ScreenSize }) => (ScreenSize === "desktop" ? "10px" : "5px")};
        height: auto;
        
        &:first-child {
            margin-left: 0;
        }
    }
    `;

export const URLAddress = styled.div`
    font-weight: ${typography.fontWeightMedium};
    font-size: .8125rem;
    color: ${color.grey[700]};
    background-color: ${color.grey[50]};
    width: 100%;
    padding: 7.5px 20px;
    border: .0625rem solid ${color.grey[200]};
    border-radius: 6.25rem;
    `;

export const Content = styled.div`
    width: 100%;
    height: 100%;
    display: block;
    position: relative;
    overflow: hidden;
    /* overflow-y: auto; */
    padding-bottom: 40px; // hack to view bottom page
`;