import BannerDefault from '@img/theme/hotsite/home/slider/banner.png';
import BannerDefaultMobile from '@img/theme/hotsite/home/slider/banner-mobile.png';
import HeaderLogo from '@img/theme/hotsite/logo@1x.png';

import Placeholder640x410 from '@img/placeholder-640x410.png';

import { theme } from '@theme';

const { color } = theme;

const nav = [
    {
        id: "1",
        title: 'Campanhas',
        link: '#campanhas'
    },
    {
        id: "2",
        title: 'Como funciona',
        link: '#como-funciona'
    },
    {
        id: "3",
        title: 'FAQ',
        link: '#duvidas-frequentes'
    },
    {
        id: "4",
        title: 'Fale Conosco',
        link: '#!',
    },
]

export const hotsiteTheme = {
    themeName: "hotsite-theme",
    header: {
        headerNav: {
            headerNavColor: color.grey[900],
            headerNavActiveColor: color.purple[900],
            headerNavData: [...nav],
        },
        headerLogo: HeaderLogo,
        headerBackgroundColor: color.common.white,
        headerButtonCreateAccount: {
            headerButtonCreateAccountWithBorder: true,
            headerButtonCreateAccountBorderColor: color.purple[900],
            headerButtonCreateAccountColor: color.purple[900],
            headerButtonCreateAccountBackgroundColor: 'transparent',
            headerButtonCreateAccountText: 'Criar conta',
            headerButtonCreateAccountLink: '#!',
        },
        headerButtonAccess: {
            headerButtonAccessWithBorder: true,
            headerButtonAccessBorderColor: color.purple[900],
            headerButtonAccessColor: color.purple[900],
            headerButtonAccessBackgroundColor: 'transparent',
            headerButtonAccessText: 'Acesse',
            headerButtonAccessLink: '#!',
        }
    },
    footer: {
        footerLogo: HeaderLogo,
        footerNav: [...nav],
        footerColor: color.grey[900],
        footerBackgroundColor: color.grey[50],
    },

    heroSlider: [
        {
            id: '998a434a',
            order: 1,
            src: BannerDefault,
            srcMobile: BannerDefaultMobile,
            link: '#!'
        },
        {   
            id: '998a4520',
            order: 2,
            src: BannerDefault,
            srcMobile: BannerDefaultMobile,
            link: '#!'
        },
        {
            id: '998a4638',
            order: 3,
            src: BannerDefault,
            srcMobile: BannerDefaultMobile,
            link: '#!'
        },
    ],
    section1: {
        section1Headline: {
            section1HeadlineText: 'Como funciona',
            section1HeadlineColor: color.deepPurple[900],
        },
        section1Styles: {
            section1StylesBackgroundColor: color.deepPurple[50],
            section1StylesStepColor: color.purple[900],
            section1StylesTitleColor: color.purple[900],
            section1StylesArrowsColors: color.purple[900]
        },
        section1ComponentData: [
            {   
                section1ComponentDataId: '4bb215f6',
                section1ComponentDataTitle: 'Escolha a campanha',
                section1ComponentDataSubtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
            },
            {
                section1ComponentDataId: '4bb21c0e',
                section1ComponentDataTitle: 'Se Inscreva',
                section1ComponentDataSubtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
            },
            {
                section1ComponentDataId: '4bb21d62',
                section1ComponentDataTitle: 'Tudo Pronto!',
                section1ComponentDataSubtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
            },
        ]
    },
    section2: {
        section2Headline: {
            section2HeadlineText: 'Campanhas ativas',
            section2HeadlineColor: color.deepPurple[900],
        },
        section2Styles: {
            section2StylesButtonComponent: {
                section2StylesButtonComponentColor: color.common.white,
                section2StylesButtonComponentBackgroundColor: color.deepPurple[900],
            },
            section2StylesSlider: {
                section2StylesSliderArrowsColors: color.grey[600],
            },
        },
        section2SectionSlider: [
            {
                section2SectionSliderId: 'a56ea67c',
                section2SectionSliderImage: Placeholder640x410,
                section2SectionSliderTitle: 'Campanha 1',
                section2SectionSliderDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
                section2SectionSliderLink: '/campaign/1',
            },
            {
                section2SectionSliderId: 'a56ea83e',
                section2SectionSliderImage: Placeholder640x410,
                section2SectionSliderTitle: 'Campanha 2',
                section2SectionSliderDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
                section2SectionSliderLink: '/campaign/2',
            },
            {
                section2SectionSliderId: 'a56ea938',
                section2SectionSliderImage: Placeholder640x410,
                section2SectionSliderTitle: 'Campanha 3',
                section2SectionSliderDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
                section2SectionSliderLink: '/campaign/3',
            },
            {
                section2SectionSliderId: 'a56eaa1e',
                section2SectionSliderImage: Placeholder640x410,
                section2SectionSliderTitle: 'Campanha 4',
                section2SectionSliderDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
                section2SectionSliderLink: '/campaign/4',
            },
            {   
                section2SectionSliderId: 'a56eab04',
                section2SectionSliderImage: Placeholder640x410,
                section2SectionSliderTitle: 'Campanha 5',
                section2SectionSliderDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
                section2SectionSliderLink: '/campaign/5',
            },
            {
                section2SectionSliderId: 'a56eabea',
                section2SectionSliderImage: Placeholder640x410,
                section2SectionSliderTitle: 'Campanha 6',
                section2SectionSliderDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
                section2SectionSliderLink: '/campaign/6',
            },
        ]
    },
    faq: {
        faqTitle: {
            faqTitleText: "FAQ",
            faqTitleColor: color.grey[900]
        },
        faqAccordion: {
            faqAccordionIconColor: color.purple[900]
        },
    },
    contact: {
        contactSectionContactColor: color.purple[900],
        contactBackgroundColorButton: color.purple[900],
        contactColorButton: color.common.white,
    },
}