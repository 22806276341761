import { theme } from '@theme';
import React from 'react';

import { Main } from './styles';

interface SectionProps {
    id?: string;
    sectionColor?: string;
    children: React.ReactNode;
}

const Section: React.FC<SectionProps> = ({ sectionColor, id, children }: SectionProps) => {

    const { color } = theme;

    return (
        <Main id={id ? id : ''} color={sectionColor ? sectionColor : color.common.white}>
            {children}
        </Main>
    )
}

export { Section };