import React from 'react';

import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';

import { Main, Head, Content, SidebarContent } from './styles';
import { theme } from '@theme';

interface SidebarProps {
    children: React.ReactNode;
}

interface SidebarHeadProps {
    title: string;
    active: boolean;
    onClick?: () => void;
    className?: "active-head" | "disabled-head";
    children: React.ReactNode;
}

const Sidebar: React.FC<SidebarProps> = ({ children }: SidebarProps) => {

    return (
        <Main>

            <Content>
                {children}
            </Content>

        </Main>
    )
}

const SidebarHead: React.FC<SidebarHeadProps> = ({ title, active, className, onClick, children }: SidebarHeadProps) => {

    const { color } = theme;

    return (
        <SidebarContent active={active} className={
            className
                ?
                className !== "active-head"
                    ? className
                    : "active-head"
                : ''}>
            <Head onClick={onClick} active={active}>
                <span>{title}</span> <I icon={active ? faChevronLeft : faChevronRight as any} color={color.grey[900]} />
            </Head>
            <>
                {children}
            </>

        </SidebarContent>
    )
}

export { Sidebar, SidebarHead };