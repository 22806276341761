import styled from 'styled-components';

import { theme } from '@theme';

const { color } = theme;

export const Main = styled.div`
    border-radius: 1px solid red;
    width: 100%;
    height: 100%;
    margin-bottom: 20px;

    & > label {
        color: ${color.grey[700]};
        font-size: .8125rem;
        margin-bottom: .3125rem;
        display: block;
    }
`;

export const BlockContent = styled.div`
   & > input {
        width: 100%;
        height: 2.125rem;
        border: 1px solid ${color.grey[200]};
        font-size: .875rem;
        padding: 8px;
    }
`;