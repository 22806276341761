import React, { useCallback, useEffect, useState } from "react";
import {Campaign} from '@services'
import {Main, P} from './styles';
import { useParams } from "react-router-dom";

const Regulament: React.FC = () => {

    const {campaignPath} = useParams();
    const [regulament, setRegulament] = useState<string>('');

    const loadRegulament = useCallback( async () => {
        const campaign = new Campaign();
        try {
            const response = await campaign.getRegulament({subdomain: campaignPath ? campaignPath : ''});
            setRegulament(response.data.regulamentCampaign);
        } catch(err) {
            setRegulament('Não foi possível consultar o regulamento.');
        }
    }, [campaignPath])

    useEffect(() => {
        loadRegulament();
    }, [loadRegulament])

    return (
        <Main dangerouslySetInnerHTML={{__html: regulament}}>
        </Main>
    )
}

export {Regulament};