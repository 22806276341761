import React from "react";
import { LinkStyled, Image } from './styles';

interface LogoIconProps {
    src: string;
    link: string;
    className?: string;
}

const LogoIcon: React.FC<LogoIconProps> = ({ src, link, className }: LogoIconProps) => {
    return (
        <LinkStyled className={className ? className : ''} to={link}>
            <Image src={src} alt="" />
        </LinkStyled>
    )
}

export { LogoIcon };