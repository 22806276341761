import React from "react";
import {HowWorksBox} from '@design-system';
import {Main, Text, Title, Subtitle} from './styles';

interface HowWorksProps {
    step: number;
    title: string;
    subtitle: string;
}

const HowWorks: React.FC<HowWorksProps> =
  ({ step, title, subtitle}: HowWorksProps) => {
    return (
        <Main className="howWorksMain">
            <HowWorksBox
              step={step}>
                <Text className="textDiv">
                    <Title className="titleColor">{title}</Title>
                    <Subtitle className="subtitleColor">{subtitle}</Subtitle>
                </Text>
            </HowWorksBox>
        </Main>
    )
}

export {HowWorks};