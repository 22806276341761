import React from "react";
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { IconProp } from '@fortawesome/fontawesome-svg-core'

import {Main, BoxContainer, BoxIcon} from './styles';

interface HowWorksBoxCampaignProps {
    iconData: IconProp;
    children: React.ReactNode;
}

const HowWorksBoxCampaign: React.FC<HowWorksBoxCampaignProps> =
  ({iconData, children}: HowWorksBoxCampaignProps) => {
    return (
        <Main className="HowWorksBoxCampaign">
            <BoxContainer className="boxContainer">
                <BoxIcon className="boxIcon">
                    <I 
                        className="icon"
                        icon={iconData}
                        style={{ height: '2.25rem'}}/>
                </BoxIcon>
                {children}
            </BoxContainer>
        </Main>
    )
}

export {HowWorksBoxCampaign};