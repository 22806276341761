import React from 'react';
import { settings, settingsSlider } from './configs';
import {
    Container,
    Slider,
    Banner,
    Section,
    Headline,
    HowWorks,
    Card,
    ParticipateButton,
    Accordion,
    AccordionSummary,
} from '@design-system';

import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';

import { Main, MainHowWorks, BoxContainer, ChevronDiv, MainSlider, CampaignWarning } from './styles';

import { Footer, Header } from '@components';

import Placeholder from '@img/placeholder-640x410.png';

import { hotsiteTheme } from '@data/theme';

import { useThemeContext } from '@hooks';
import { location } from '@utils'
import { NotFound } from '../common/not-found';

const Hotsite: React.FC = () => {

    const { hotsite, subdomainStatus } = useThemeContext();

    const settingsBannersSlider = {
        ...settingsSlider,
        nextArrow: <button><I icon={['far', 'chevron-right']} size="3x" /></button>,
        prevArrow: <button><I icon={['far', 'chevron-left']} size="3x" /></button>,
    };

    // const sliderSettings = {
    //     ...settings,
    //     nextArrow: <button><I icon={['far', 'chevron-right']} size="2x" color={
    //         hotsiteTheme
    //             ? hotsiteTheme.section2.section2Styles.section2StylesSlider.section2StylesSliderArrowsColors
    //             : '#CCCCCC'
    //     } /></button>,
    //     prevArrow: <button><I icon={['far', 'chevron-left']} size="2x" color={
    //         hotsiteTheme
    //             ? hotsiteTheme.section2.section2Styles.section2StylesSlider.section2StylesSliderArrowsColors
    //             : '#CCCCCC'
    //     } /></button>,
    // }

    const [isOpen, setIsOpen] = React.useState<number>(0);
    const [isOpenChildren, setIsOpenChildren] = React.useState<number>(0);
    const [sliderSettings, setSliderSettings] = React.useState<any>({
        ...settings,
        nextArrow: <button><I icon={['far', 'chevron-right']} size="2x" color={
            hotsiteTheme
                ? hotsiteTheme.section2.section2Styles.section2StylesSlider.section2StylesSliderArrowsColors
                : '#CCCCCC'
        } /></button>,
        prevArrow: <button><I icon={['far', 'chevron-left']} size="2x" color={
            hotsiteTheme
                ? hotsiteTheme.section2.section2Styles.section2StylesSlider.section2StylesSliderArrowsColors
                : '#CCCCCC'
        } /></button>,
    });

    const toggleOpen = (id: number) => () => {
        setIsOpen((isOpen: number) => isOpen === id ? 0 : id);
        setIsOpenChildren(prev => prev = 0);
    };

    const toggleOpenChildren = (id: number) => () => setIsOpenChildren(
        (isOpenChildren: number) => isOpenChildren === id ? 0 : id,
    );

    React.useEffect(() => {
        if(hotsite.isLoaded) {
            if(hotsite.campaigns.data.campaigns.length > 2) {
                setSliderSettings(
                    (prev: any) => prev = { ...prev, ...settings }
                )
            } else if (hotsite.campaigns.data.campaigns.length === 2) {
                setSliderSettings(
                    (prev: any) => prev = { ...prev, slidesToShow: 2}
                )
            } else {
                setSliderSettings(
                    (prev: any) => prev = { ...prev, slidesToShow: 1}
                )
            }
        }
    }, [hotsite]);

    return (
        <Main>
            <>
                {hotsite.isLoaded ? (
                    <>
                        <Header
                            theme={hotsite.texts.data.themeName}
                            className='headerHotsite'
                            fontColor={'white'}
                            activeMenuColorLink={'blue'}
                            backgroundColor={'black'}
                            logo={`${hotsite.texts.data.header.headerLogo}`}
                            navData={hotsite.texts.data.header.headerNav.headerNavData}
                            buttonCreateAccount={hotsite.texts.data.header.headerButtonCreateAccount}
                            buttonAccess={hotsite.texts.data.header.headerButtonAccess}
                        />
                        <Slider className='main-slider' {...settingsBannersSlider}>
                            {hotsite.texts.data.heroSlider
                                .map(({ id, src, srcMobile, link }) => (
                                    <Banner
                                        key={id}
                                        src={`${location.filesPath}${src}`}
                                        srcMobile={`${location.filesPath}${srcMobile}`}
                                        link={link}
                                    />
                                ))
                            }
                        </Slider>
                        <Section id='como-funciona'>
                            <Container>
                                <Headline className='headlineColor'>
                                    {hotsite.texts.data.section1.section1Headline.section1HeadlineText}
                                </Headline>

                                <MainHowWorks className='mainHowItWorks'>
                                    {hotsite.texts.data.section1.section1ComponentData.map(
                                        ({ section1ComponentDataId, section1ComponentDataTitle, section1ComponentDataSubtitle }, i) => {
                                            const index = i + 1;
                                            return (
                                                <BoxContainer className='boxContainer' key={section1ComponentDataId}>
                                                    <HowWorks
                                                        step={index}
                                                        title={section1ComponentDataTitle}
                                                        subtitle={section1ComponentDataSubtitle} />

                                                    <ChevronDiv className='chevronDiv'>
                                                        <I
                                                            icon={['fas', 'chevron-double-right']}
                                                            style={{ height: '1.5rem' }}
                                                            className="iconColor" />
                                                    </ChevronDiv>
                                                </BoxContainer>
                                            )
                                        })}
                                </MainHowWorks>
                            </Container>
                            <div id="campanhas" />
                        </Section>

                        <Section id='active-campaigns'>
                            <Container>
                                <Headline className='headlineColor'>
                                    {hotsite.texts.data.section2.section2Headline.section2HeadlineText}
                                </Headline>
                                <MainSlider className='mainSlider'>

                                    {hotsite.campaigns.data.campaigns.length > 0 ?
                                        <Slider {...sliderSettings} className='campaign-slider'>
                                            {hotsite.campaigns.data.campaigns.map(({ _id, name, description, websiteAlias }) => {
                                                return <Card
                                                    key={_id}
                                                    image={Placeholder}
                                                    title={name}
                                                    description={description}
                                                >
                                                    <ParticipateButton
                                                        link={ `${websiteAlias}`} />
                                                </Card>
                                            })}
                                        </Slider>
                                    :
                                        <CampaignWarning>
                                            <span>No momento não há campanhas ativas!</span>
                                        </CampaignWarning>
                                    }
                                </MainSlider>
                            </Container>
                        </Section>

                        <Section id="duvidas-frequentes">
                            <Container>
                                <Headline className='headlineColor'>
                                    {hotsite.texts.data.faq.faqTitle.faqTitleText}
                                </Headline>
                                {hotsite.texts.data.faq.faqContent.map(({ id, title, questions }, i) => {
                                    const idx = i + 1;
                                    return (
                                        <Accordion
                                            key={`${id}`}
                                            title={title}
                                            className={'accordionHotsite'}
                                            onClick={toggleOpen(idx)}
                                            isActive={isOpen === idx}
                                        >
                                            {questions.map(({ id, title, text }, i) => {
                                                const index = i + 1;
                                                return (
                                                    <AccordionSummary
                                                        key={`${id}`}
                                                        count={index}
                                                        title={title}
                                                        onClick={toggleOpenChildren(index)}
                                                        isActive={isOpenChildren === index}>
                                                        {text}
                                                    </AccordionSummary>
                                                )
                                            })}
                                        </Accordion>
                                    )
                                })}
                            </Container>
                        </Section>

                        <Footer
                            theme={hotsite.texts.data.themeName}
                            logo={`${location.filesPath}${hotsite.texts.data.header.headerLogo}`}
                            backgroundColor={hotsiteTheme.footer.footerBackgroundColor}
                            fontColor={hotsiteTheme.footer.footerColor}
                            navData={hotsiteTheme.footer.footerNav}
                        />
                    </>
                ) : ''}

            </>

            <>
                {subdomainStatus && (<NotFound />)}
            </>

        </Main>
    );
}

export { Hotsite };