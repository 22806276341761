import styled from 'styled-components';
import {theme} from '@theme';

interface Props {
    backgroundColor: string;
    fontColor: string;
}

export const Main = styled('footer')`
    padding-top: 30px;
    background-color: ${(props: Props) => props.backgroundColor};
    color: ${(props: Props) => props.fontColor};
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;

    ${theme.breakpoints.down('md',
        `padding-top: 40px;
        
        `
    )}
`;

export const ContainerStyled = styled('div')`
    display: flex;
    padding: 0 15px;
    width: 100%;
    max-width: 1140px;
    justify-content: space-between;

    ${theme.breakpoints.down('md',
        `flex-direction: column;
        `
    )}

`;


export const Nav = styled('nav')`

    /* ${theme.breakpoints.down('md',
        `margin-top: 30px;
        
        `
    )} */

`;

export const Ul = styled('ul')``;

export const Informative = styled('div')`
    font-size: 12px;
    display: flex;
    align-items: center;

    & > span {
        padding-right: 5px;
    }
    &.on-footer {
        ${theme.breakpoints.down('md',
            `
            text-align: center;
            margin-top: 20px;
            margin-bottom: 12px;
            `
        )}
    }

    ${theme.breakpoints.down('md',
        `&:last-child {
            margin-top: 12px;
        }`
    )}
`;

export const CompanyInfo = styled('div')`
    height: 50px;
    margin-top: 76px;
    width: 100%;
    background-color: #EEEEEE;
    display: flex;
    justify-content: center;

    & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;

        ${theme.breakpoints.down('md',
            `justify-content: center;
             align-items: flex-start;
            `
        )}
    }

    ${theme.breakpoints.down('md',
        `height: 87px;
        margin-top: 40px;
        justify-content: start;
        `
    )}
`;
