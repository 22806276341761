import styled from 'styled-components';

import { theme } from '@theme';

const { typography, breakpoints } = theme;

export const Main = styled.h1`
  font-size: 2.25rem;
  font-weight: ${typography.fontWeightBold};
  text-align: center;
  margin-bottom: 1.5625rem;
  
  ${breakpoints.down('md', `
    font-size: 1.625rem;
  `)}
`;
