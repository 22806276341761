import { isObject } from './isObject';

function recursiveFindInObject(object: Object, keyToMatch: string): any {
    if (isObject(object)) {
        const entries = Object.entries(object);

        for (let i = 0; i < entries.length; i += 1) {

            const [objectKey, objectValue] = entries[i];

            if (objectKey === keyToMatch) {
                return object;
            }

            if (isObject(objectValue)) {
                const child = recursiveFindInObject(objectValue, keyToMatch);

                if (child !== null) {
                    return child;
                }
            }
        }
    }

    return null;
}

export { recursiveFindInObject }