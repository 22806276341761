import styled from 'styled-components';

import { theme } from '@theme';

const { color, typography, border } = theme;

export const Main = styled.div`
    height: 4.125rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 3px 6px rgba(0,0,0,0.05);
    position: relative;
`;

export const ButtonBack = styled.div`
    height: 66px;
    max-width: 125px;
    width: 100%;
    padding: 0 1.875rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-right: .0625rem solid ${color.grey[200]};

    & > span {
        display: inline-block;
        margin-left: .625rem;
        font-size: .875rem;
        font-weight: ${typography.fontWeightRegular};
    }
`;

export const Columns = styled.div`
    height: 66px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Column = styled.div`
    &:nth-child(2) {
        max-width: 150px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
    }

    &:nth-child(3) {
        padding-right: 1.875rem;
    }
`;


export const ButtonSwitchResl = styled.button`
    margin: .3125rem;
    max-width: 70px;
    width: 100%;
    border: 0;
    padding: 0;
    border-radius: ${border.input};
    overflow: hidden;
    
    & > label {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        position: relative;
        cursor: pointer;

        & > input {
            display: none;
            visibility: hidden;
            opacity: 0;
        }

        & > span.mark {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: transparent;
        }

        & > svg {
            position: relative;
            z-index: 1;
            & > path {
                fill: ${color.common.white};
            }
        }

        & > input:checked ~ span.mark {
            background-color: ${color.grey[900]};
        }
    }
`;
