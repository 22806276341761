import React from "react";

import { Main } from './styles';

import { LazyLoadImage } from 'react-lazy-load-image-component';

interface BannerProps {
    link?: string;
    src: string;
    srcMobile: string;
}

const Banner: React.FC<BannerProps> = ({ link, src, srcMobile }: BannerProps) => {

    return (
        <Main className="WrapAnchorPicture">
            <a href={link} className="AnchorPicture" target={link === "#!!" || link === "#!" ? "_self" : "_blank" }rel="noreferrer">
                <div className="WrapPicture">
                    <picture>
                        <source media="(max-width: 650px)" srcSet={srcMobile} />
                        <LazyLoadImage
                            src={src} // use normal <img> attributes as props
                            alt="Slider image"
                            />
                    </picture>
                </div>
            </a>
        </Main>
    )
}

export { Banner };