import React from 'react';

import { Main, WrapperChildren } from './styles';

interface SelectColorProps {
    label: string;
    id: string;
    children: React.ReactNode;
}

const SelectColor: React.FC<SelectColorProps> = ({ label, id, children }: SelectColorProps) => {

    return (
        <Main>
            <label htmlFor={id}>{label}</label>
            <WrapperChildren>
                {children}
            </WrapperChildren>
        </Main>
    )
}

export { SelectColor };