import React from "react";
import { FontAwesomeIcon as I } from '@fortawesome/react-fontawesome';
import {Main, Button, ArrowDiv, Text} from './styles';
import { Link } from "react-router-dom";

interface ParticipateButtonProps {
    link: string;
}

const ParticipateButton: React.FC<ParticipateButtonProps> =
  ({link}: ParticipateButtonProps) => {
    // const url = window.location.hostname;
    
    return (
        <Main className="participateButton">
            <a target={'_blank'} href={`${link}`} className="anchor" rel="noreferrer">
                <Button className="buttonElement">
                    <Text>
                        Participar
                    </Text>
                    <ArrowDiv className="arrow">
                        <I
                        className="arrowIcon" 
                        icon={['far', 'chevron-right']}
                        style={{ height: '0.8125rem'}}/>
                    </ArrowDiv>
                </Button>
            </a>
        </Main>
    )
}

export {ParticipateButton};