export const settings = {
  className: 'slick-carousel-center',
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  centerPadding: 30,
  responsive: [
    // {
    //   breakpoint: 768,
    //   settings: {
    //     slidesToShow: 2,
    //     slidesToScroll: 1,
    //     centerMode: true,
    //   }
    // },
    {
      breakpoint: 668,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
      }
    },
  ]
};

export const settingsSlider = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};