import styled from 'styled-components';

import { theme } from '@theme';

const { color, zIndex, typography, border } = theme;

export const Main = styled.div`
    border-radius: 1px solid red;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
`;

export const Title = styled.span`
    color: ${color.grey[700]};
    font-size: .8125rem;
    margin-bottom: .3125rem;
    display: block;
`;

export const WrapperChildren = styled.div`
    position: relative;
    & > input {
        display: none;
    }
    
    &:hover {
        & > .overlay {
            opacity: 1;
            -webkit-transition: all 0.20s ease-in-out;
            -moz-transition: all 0.20s ease-in-out;
            -ms-transition: all 0.20s ease-in-out;
            -o-transition: all 0.20s ease-in-out;
            transition: all 0.20s ease-in-out;
        }
    }
`;

export const WrapAnchorPicture = styled.div`
    height: 100vh;
    max-height: 84px;
    width: 100%;
    border: .0625rem dashed ${color.grey[400]};
    cursor: pointer;
    border-radius: ${border.input};
    overflow: hidden;
    
    &:hover {
        border: .0625rem dashed ${color.grey[600]};
    }

    & > .AnchorPicture {
        display: block;
        height: 100%;
        width: 100%;
        overflow: hidden;
        
        & > .WrapPicture {
            height: 100%;
            width: 100%;
            picture {
                display: contents;
                
                source,
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border: 0;
                    max-width: 100%;
                    vertical-align: middle;
                    display: table-caption !important;
                    font-size: .75rem;
                    color: ${color.grey[700]};;
                }
            }
        }
    }
    
`;

export const Label = styled.label`
    position: relative;
    width: 100%;
`;

export const Overlay = styled.div`
    background-color: rgba(0,0,0,0.7);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: ${zIndex.max};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${border.input};
    opacity: 0;
    -webkit-transition: all 0.20s ease-in-out;
    -moz-transition: all 0.20s ease-in-out;
    -ms-transition: all 0.20s ease-in-out;
    -o-transition: all 0.20s ease-in-out;
    transition: all 0.20s ease-in-out;
    cursor: pointer;
`;

export const OverlayContent = styled.div`
display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

export const OverlayText = styled.p`
    color: ${color.common.white};
    font-weight: ${typography.fontWeightRegular};
    font-size: 12px;
    margin-top: .375rem;
`;
