import React from "react";
import {Button} from '@design-system';

interface ButtonIconProps {
    text: string;
    iconSrc: React.ReactNode;
    link?: string;
    outPage?: boolean;
    onClick?: () => void;
}

const ButtonIcon: React.FC<ButtonIconProps> = ({
    iconSrc, link, text, onClick, outPage}: ButtonIconProps) => {
    return (
        <Button
        className="button-icon"
        link={link}
        text={text}
        onClick={onClick}
        outPage={outPage}
        withBorder={false}>
            {iconSrc}
        </Button>
    )
}

export {ButtonIcon};