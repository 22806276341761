import React from 'react';
import { Routes as AppRoutes, Route, useLocation } from 'react-router-dom';
import {
    Hotsite,
    Campaign,
    Customization,
    TermsOfUse,
    PrivacyPolicies,
    NotFound
} from '@pages';

import { path } from './route-paths';

const Routes = () => {

    const { pathname } = useLocation();

    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <React.Fragment>
            <AppRoutes>
                <Route index={true} element={<Hotsite />} />
                <Route path={`/terms-of-use`} element={<TermsOfUse />} />
                <Route path={`/:campaignPath/terms-of-use`} element={<TermsOfUse />} />
                <Route path={`/privacy-policies`} element={<PrivacyPolicies />} />
                <Route path={`/:campaignPath/privacy-policies`} element={<PrivacyPolicies />} />
                <Route path={`/:campaignPath`} element={<Campaign />} />
                <Route>
                    <Route path={`${path.design}`} element={<Customization />} />
                </Route>
                <Route path='*' element={<NotFound />} />

            </AppRoutes>
        </React.Fragment>
    )
}

export default Routes;