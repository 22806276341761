import React from "react";

import { hotsiteTheme } from '@data/theme';
import { HotsiteThemeProps } from '@interfaces/theme';

interface useStorageThemeProps {
    theme: HotsiteThemeProps;
    isLoadingTheme: boolean;
    saveOnStorageTheme: (themeToSave: HotsiteThemeProps) => void;
}

const useStorageTheme = (): useStorageThemeProps => {

    const [isLoadingTheme, setIsLoadingTheme] = React.useState<boolean>(false);

    const [storageKey] = React.useState(() => {
        return "@Customization:hotsite"
    })

    const [theme, setTheme] = React.useState<HotsiteThemeProps>(() => {

        const storagedCustomization = localStorage.getItem(storageKey);

        if (storagedCustomization) {
            return JSON.parse(storagedCustomization);
        } else {
            return hotsiteTheme;
        }
    });

    const defaultTheme = React.useMemo(() => (hotsiteTheme), []);

    const saveOnStorageTheme = React.useCallback((themeToSave: HotsiteThemeProps) => {
        localStorage.setItem("@Customization:hotsite", JSON.stringify(themeToSave));
        setTimeout(() => setIsLoadingTheme(prev => prev = true), 1000);
    }, []);

    const storageEvent = React.useCallback((event: StorageEvent) => {

        if (event.key === storageKey) {
            const { newValue } = event;
            const parse = JSON.parse(newValue as string);
            setTheme(parse);
        }
    }, [storageKey]);

    const prevThemeRef = React.useRef<HotsiteThemeProps>();

    React.useEffect(() => {
        prevThemeRef.current = theme;
    });

    const themePrevValue = prevThemeRef.current ?? theme;

    React.useEffect(() => {
        if (themePrevValue !== theme) {
            localStorage.setItem(storageKey, JSON.stringify(theme));
            setTimeout(() => setIsLoadingTheme(prev => prev = true), 1000);

        }
    }, [theme, themePrevValue, storageKey]);

    React.useEffect(() => {

        window.addEventListener('storage', storageEvent);

        return () => {
            window.removeEventListener('storage', storageEvent);
        }

    }, [storageEvent]);

    const setThemelocalStorage = React.useCallback(() => {
        const verifyTheme = Object.keys(theme).length > 0;
        if (verifyTheme) {
            return theme;
        } else {
            return saveOnStorageTheme(defaultTheme);
        }

    }, [defaultTheme, theme, saveOnStorageTheme]);

    React.useEffect(() => {
        setThemelocalStorage()
    }, [setThemelocalStorage]);

    return { theme, isLoadingTheme, saveOnStorageTheme }

}

export { useStorageTheme };