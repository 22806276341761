// import Slider from "react-slick";
import { default as SliderBanners } from 'react-slick';

import React from "react";

interface BannersSliderProps {
  className?: string;
  children: React.ReactNode;
}


const Slider: React.FC<BannersSliderProps> = (props: BannersSliderProps) => {

  const { className, children } = props;

  return (
    <SliderBanners className={`${className ? className : ''}`} {...props}>
      {children}
    </SliderBanners>
  );
}

export { Slider }