export const faq = [
    {
      id: '01b3cd8a',
      title: 'Sobre a promoção',
      questions: [
        {
          id: 'f796db42',
          title: 'O Que É Essa Promoção E Quem Pode Participar?',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec posuere porttitor dignissim. Suspendisse potenti. Suspendisse rutrum lacus ut nunc dignissim cursus. Etiam varius purus nec molestie facilisis. Maecenas a elementum tortor. Suspendisse potenti. Mauris rhoncus tincidunt massa et tincidunt.',
        },
        {
          id: '8b945e30',
          title: 'Quem Não Pode Participar Da Promoção?',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec posuere porttitor dignissim. Suspendisse potenti. Suspendisse rutrum lacus ut nunc dignissim cursus. Etiam varius purus nec molestie facilisis. Maecenas a elementum tortor. Suspendisse potenti. Mauris rhoncus tincidunt massa et tincidunt.',
        },
        {
          id: '0ac19d88',
          title: 'Como Vou Saber Se A Promoção É Segura?',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec posuere porttitor dignissim. Suspendisse potenti. Suspendisse rutrum lacus ut nunc dignissim cursus. Etiam varius purus nec molestie facilisis. Maecenas a elementum tortor. Suspendisse potenti. Mauris rhoncus tincidunt massa et tincidunt.',
        },
      ]
    },
    {
      id: 'cab0bdeb',
      title: 'Como participar',
      questions: [
        {
          id: '674c5a7c',
          title: 'Como faço para participar da promoção?',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec posuere porttitor dignissim. Suspendisse potenti. Suspendisse rutrum lacus ut nunc dignissim cursus. Etiam varius purus nec molestie facilisis. Maecenas a elementum tortor. Suspendisse potenti. Mauris rhoncus tincidunt massa et tincidunt.',
        },
        {
          id: '8b941e30',
          title: 'Como realizar o cadastro?',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec posuere porttitor dignissim. Suspendisse potenti. Suspendisse rutrum lacus ut nunc dignissim cursus. Etiam varius purus nec molestie facilisis. Maecenas a elementum tortor. Suspendisse potenti. Mauris rhoncus tincidunt massa et tincidunt.',
        },
        {
          id: '0ac16d88',
          title: 'Quem são os participantes?',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec posuere porttitor dignissim. Suspendisse potenti. Suspendisse rutrum lacus ut nunc dignissim cursus. Etiam varius purus nec molestie facilisis. Maecenas a elementum tortor. Suspendisse potenti. Mauris rhoncus tincidunt massa et tincidunt.',
        },
      ]
    },
    {
      id: '9f15b938',
      title: 'Sobre as premiações',
      questions: [
        {
          id: '4997eoe9',
          title: 'Quais são os prêmios?',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec posuere porttitor dignissim. Suspendisse potenti. Suspendisse rutrum lacus ut nunc dignissim cursus. Etiam varius purus nec molestie facilisis. Maecenas a elementum tortor. Suspendisse potenti. Mauris rhoncus tincidunt massa et tincidunt.',
        },
        {
          id: '8b945i30',
          title: 'Como saber quem são os ganhadores?',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec posuere porttitor dignissim. Suspendisse potenti. Suspendisse rutrum lacus ut nunc dignissim cursus. Etiam varius purus nec molestie facilisis. Maecenas a elementum tortor. Suspendisse potenti. Mauris rhoncus tincidunt massa et tincidunt.',
        },
        {
          id: '0ac19r88',
          title: 'O que preciso fazer para receber a premiação?',
          text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec posuere porttitor dignissim. Suspendisse potenti. Suspendisse rutrum lacus ut nunc dignissim cursus. Etiam varius purus nec molestie facilisis. Maecenas a elementum tortor. Suspendisse potenti. Mauris rhoncus tincidunt massa et tincidunt.',
        },
      ]
    },
];