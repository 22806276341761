import BannerDefault from '@img/theme/campaign/home/slider/banner@2x.png';
import BannerDefaultMobile from '@img/theme/campaign/home/slider/banner-mob@2x.png';

import { theme } from '@theme';

import CapaignColumn1 from '@img/theme/campaign/home/campaign-column-1.png';
import CapaignColumn2 from '@img/theme/campaign/home/campaign-column-2.png';
import HeaderLogo from '@img/theme/campaign/logo@1x.png';
import Placeholder640x410 from '@img/placeholder-640x410.png';

const { color } = theme;

const nav = [
    {
        id: "1",
        title: 'Como participar',
        link: '#como-participar'
    },
    {
        id: "2",
        title: 'Regulamento',
        link: '#!'
    },
    {
        id: "3",
        title: 'FAQ',
        link: '#duvidas-frequentes'
    },
    {
        id: "4",
        title: 'Fale Conosco',
        link: '#fale-conosco'
    }
]

export const campaignTheme = {
    themeName: "campaign-theme",
    header: {
        headerNav: {
            headerNavColor: color.grey[900],
            headerNavActiveColor: color.purple[900],
            headerNavData: [...nav],
        },
        headerLogo: HeaderLogo,
        headerBackgroundColor: color.deepOrange[50],
        headerButtonCreateAccount: {
            headerButtonCreateAccountWithBorder: true,
            headerButtonCreateAccountBorderColor: theme.color.purple[900],
            headerButtonCreateAccountColor: theme.color.purple[900],
            headerButtonCreateAccountBackgroundColor: 'transparent',
            headerButtonCreateAccountText: 'Criar conta',
            headerButtonCreateAccountLink: '',
        },
        headerButtonAccess: {
            headerButtonAccessWithBorder: true,
            headerButtonAccessBorderColor: theme.color.purple[900],
            headerButtonAccessColor: theme.color.purple[900],
            headerButtonAccessBackgroundColor: 'transparent',
            headerButtonAccessText: 'Acesse',
            headerButtonAccessLink: '',
        }
    },
    footer: {
        footerLogo: HeaderLogo,
        footerNav: [...nav],
        footerColor: theme.color.grey[900],
        footerBackgroundColor: theme.color.grey[50],
    },
    heroSlider: [
        {
            id: 'e51ce564',
            order: 1,
            src: BannerDefault,
            srcMobile: BannerDefaultMobile,
            link: ''
        },
        {
            id: 'e51ce820',
            order: 2,
            src: BannerDefault,
            srcMobile: BannerDefaultMobile,
            link: ''
        },
        {
            id: 'e51ce96a',
            order: 3,
            src: BannerDefault,
            srcMobile: BannerDefaultMobile,
            link: ''
        },
        {
            id: 'e51ceaaa',
            order: 4,
            src: BannerDefault,
            srcMobile: BannerDefaultMobile,
            link: ''
        },
    ],
    section1: {
        section1Headline: {
            section1HeadlineText: 'Como participar',
            section1HeadlineColor: color.deepPurple[900],
        },
        section1Styles: {
            section1StylesIconColor: theme.color.common.white,
            section1StylesIconBackground: theme.color.deepPurple[900],
            section1StylesBackgroundColor: theme.color.grey[200],
            section1StylesColor: theme.color.grey[900],
        },
        section1ComponentData: [
            {
                section1ComponentDataId: '193dfba2',
                section1ComponentDataIconData: ['fal', 'sign-in'],
                section1ComponentDataTitle: 'Cadastre-se',
                section1ComponentDataSubtitle: 'Para acumular pontos basta estar cadastrado no programa.'
            },
            {
                section1ComponentDataId: '193dfd64',
                section1ComponentDataIconData: ['far', 'braille'],
                section1ComponentDataTitle: 'Ganhe Pontos',
                section1ComponentDataSubtitle: 'Ganhe 1 ponto a cada 1 real gasto em suas compras.'
            },
            {
                section1ComponentDataId: '193dfe68',
                section1ComponentDataIconData: ['far', 'smile-beam'],
                section1ComponentDataTitle: 'Troque Seus Pontos',
                section1ComponentDataSubtitle: 'Aproveite as ofertas turbinadas que fazemos ao longo do ano.'
            },
        ]
    },
    section2: {
        section2_box1: {
            section2Box1Title: 'Ainda não é Cadastrado? ',
            section2Box1Text: 'Para acumular pontos basta estar cadastrado no programa. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            section2Box1Image: CapaignColumn1,
            section2Box1Styles: {
                section2Box1TitleColor: color.purple[900],
                section2Box1TextColor: color.common.black,
            },
            section2Box1Button: {
                section2Box1ButtonLink: '#!',
                section2Box1ButtonText: 'Cadastre-se agora',
                section2Box1ButtonWithBorder: false,
                section2Box1ButtonBackgroundColor: color.amber[500],
                section2Box1ButtonBorderColor: color.amber[500],
                section2Box1ButtonColor: color.grey[900],
                section2Box1ButtonColorIcon: color.grey[900],
            }
        },
        section2_box2: {
            section2_box2_title: 'Já é Cadastrado? ',
            section2_box2_text: 'Para acumular pontos basta estar cadastrado no programa. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
            section2_box2_image: CapaignColumn2,
            section2_box2_styles: {
                section2_box2_title_color: color.purple[900],
                section2_box2_text_color: color.common.black,
            },
            section2_box2_button: {
                section2_box2_button_link: '#!',
                section2_box2_button_text: 'Ir para o portal',
                section2_box2_button_withBorder: false,
                section2_box2_button_backgroundColor: color.deepOrange[50],
                section2_box2_button_borderColor: color.deepOrange[50],
                section2_box2_button_color: color.purple[900],
                section2_box2_button_colorIcon: color.purple[900],
            }
        }
    },
    section3: {
        section3_headline: {
            section3HeadlineText: 'Conheça nossos parceiros',
            section3HeadlineColor: color.deepPurple[900],
        },
        section3_styles: {
            section3StylesSlider: {
                section3StylesSliderArrowColor: color.grey[600],
            },
        },
        section3_backgroundSectionColor: color.grey[50],
        section3_sectionSlider: [
            {
                section3SectionSliderId: '5388f028',
                section3SectionSliderImage: Placeholder640x410,
                section3SectionSliderTitle: 'Campanha 1',
                section3SectionSliderDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
            },
            {
                section3SectionSliderId: '5388f208',
                section3SectionSliderImage: Placeholder640x410,
                section3SectionSliderTitle: 'Parceiro 2',
                section3SectionSliderDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
            },
            {
                section3SectionSliderId: '5388f316',
                section3SectionSliderImage: Placeholder640x410,
                section3SectionSliderTitle: 'Parceiro 3',
                section3SectionSliderDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
            },
            {
                section3SectionSliderId: '5388f41a',
                section3SectionSliderImage: Placeholder640x410,
                section3SectionSliderTitle: 'Parceiro 4',
                section3SectionSliderDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
            },
            {
                section3SectionSliderId: '5388f50a',
                section3SectionSliderImage: Placeholder640x410,
                section3SectionSliderTitle: 'Parceiro 5',
                section3SectionSliderDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
            },
            {
                section3SectionSliderId: '5388f794',
                section3SectionSliderImage: Placeholder640x410,
                section3SectionSliderTitle: 'Parceiro 6',
                section3SectionSliderDescription: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
            },
        ]
    },
    faq: {
        faqTitle: {
            faqTitleText: "FAQ",
            faqTitleColor: color.purple[900]
        },
        faqAccordion: {
            faqAccordionIconColor: color.deepOrange.A400
        },
    },
    contact: {
        contactSectionContactColor: color.purple[900],
        contactBackgroundColorButton: color.purple[900],
        contactColorButton: color.common.white,
    },
}