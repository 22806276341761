import React, { createContext, useContext, useCallback } from "react";
import { AxiosError } from 'axios';

import { Campaign, Hotsite } from '@services';
import { location } from '@utils'

import {
    HotsiteProps,
    ActiveCampaigns,
    LoadProps,
    TextsJsonProps,
    ThemeData,
    ThemeProviderProps,
    ResponseCampaignData,
} from './interfaces';


const ThemeContext = createContext<ThemeData>({} as ThemeData);

export const ThemeProvider = ({ children }: ThemeProviderProps): JSX.Element => {

    const [subdomainStatus, setSubdomainStatus] = React.useState<boolean>(false);
    const [hotsite, setHotsite] = React.useState<HotsiteProps>({} as HotsiteProps);

    const load = useCallback(async ({ domain, subdomain, fileLoad }: LoadProps) => {

        let response = {};
 
        try {
            if (domain === "hotsite") {

                const hotsite = new Hotsite();
                const responseHotsite = await hotsite.listFiles({
                    subdomain: subdomain,
                    fileLoad: fileLoad as string
                });

                const data = await responseHotsite.data;

                return response = {
                    data: data,
                    error: {
                        status: true,
                        message: ''
                    },
                }
            }

            if (domain === "campaign") {

                const campaign = new Campaign();
                const responseCampaign = await campaign.listCampaigns({ subdomain: subdomain });
                const data = await responseCampaign.data;

                return response = {
                    data: data,
                    error: {
                        status: false,
                        message: ''
                    },
                }
            }

            if (domain === "listFilesCampaign") {

                const campaign = new Campaign();
                const responseCampaign = await campaign.listFiles({
                    subdomain: subdomain,
                    campaign: location.campaign ? location.campaign : '',
                    fileLoad: fileLoad as string
                });
    
                const data = await responseCampaign.data;

                return response = {
                    data: data,
                    error: {
                        status: false,
                        message: ''
                    },
                }
            }

        } catch (error) {

            console.log('ERROR LOAD: ', error);
            response = {
                data: null,
                error: {
                    status: true,
                    errorCode: 400,
                    message: '[E001] - Ocorreu um erro ao buscar os arquivos no servidor.'
                },
            }

            if (error instanceof AxiosError) {
                if (
                    error.response?.status === 400
                    || error.response?.status === 404
                ) {
                    response = {
                        data: null,
                        error: {
                            status: true,
                            errorCode: error.response?.status,
                            message: '[E001] - Ocorreu um erro ao buscar os arquivos no servidor.'
                        },
                    }

                }
            }
        } finally {
            return response;
        }

    }, []);

    const loadHotsiteData = React.useCallback(async () => {

        setHotsite(prev => prev = {
            ...prev,
            isLoaded: false,
            texts: {
                data: {} as any,
            },
            campaigns: {
                data: [] as any,
            },

        });

        const textsJSON = await load({
            domain: 'hotsite',
            subdomain: location.subdomain,
            fileLoad: 'texts.json'
        }) as TextsJsonProps;

        const listCampaigns = await load({
            domain: 'campaign',
            subdomain: location.subdomain
        }) as ActiveCampaigns;

        const errors = (
            textsJSON.error?.errorCode === 400
            || textsJSON.error?.errorCode === 404
            || listCampaigns.data.error?.errorCode === 400
            || listCampaigns.data.error?.errorCode === 404
        )

        if (errors) return setSubdomainStatus(prev => prev = true);

        if (textsJSON.error?.status || listCampaigns.data.error?.status) {

            setHotsite(prev => prev = {
                ...prev,
                isLoaded: true,
                texts: {
                    data: textsJSON.data,
                    error: {
                        message: '',
                        status: false,
                    }
                },
                campaigns: {
                    data: {
                        campaigns: listCampaigns.data.campaigns.filter(campaign => campaign.status === "ACTIVE"),
                        error: {
                            message: '',
                            status: false,
                        }
                    },
                },
            });
        }

    }, [load]);

    const loadCampaignData = React.useCallback(async ({campaignName}:{campaignName: string}) => {

        // setCampaign(prev => prev = {
        //     ...prev,
        //     isLoaded: false,
        //     texts: {
        //         data: {} as any,
        //     },
        // });
        const campaign = new Campaign();
        let response = {} as ResponseCampaignData;

        try {
            const responseCampaign = await campaign.listFiles({
                subdomain: location.subdomain,
                campaign: campaignName ? campaignName : '',
                fileLoad: 'texts.json'
            });

            // const partnersCampaign = await campaign.listLoyaltyPartner({
            //     subdomain: location.subdomain,
            // })

            const data = await responseCampaign.data;
            
            // const partnersData = await partnersCampaign.data;
            // const redemptionPartners = await partnersCampaign.data.listLoyaltyPartner.loyalty.redemptionPartners;

            // console.log('DATA CAMPAIGN', data)
            // console.log('PARTNERS DATA', partnersData)
            // console.log('REDEMPTION PARTNERS', redemptionPartners)

            const errors = (
                data === 400
                || data === 401
                || data === 404
            )

            console.log('ERRORS', errors)

            if (errors) {
                setSubdomainStatus(prev => prev = true); 
                throw new Error("Erro ao carregar a campanha");
            } else {
                return response = {
                    data: data,
                    error: {
                        message: '',
                        status: false,
                    }
                } as ResponseCampaignData
            }

            // const textsJSON = await load({
            //     domain: 'listFilesCampaign',
            //     subdomain: location.subdomain,
            //     fileLoad: 'texts.json'
            // }) as TextsJsonCampaignProps;
    
            // const errors = (
            //     textsJSON.error?.errorCode === 400
            //     || textsJSON.error?.errorCode === 404
            // )
    
            // if (errors) {
            //     setSubdomainStatus(prev => prev = true); 
            //     throw new Error("Erro ao carregar a campanha");
            // }
    
            // if (!textsJSON.error?.status) {
            //     setCampaign(prev => prev = {
            //         ...prev,
            //         isLoaded: true,
            //         texts: {
            //             data: textsJSON.data,
            //             error: {
            //                 message: '',
            //                 status: false,
            //             }
            //         },
            //     });

            //     return response = {
            //         data: textsJSON.data,
            //         error: {
            //             message: '',
            //             status: false,
            //         }
            //     }
            // }
            
        } catch (error: any) {
            console.log('ERROR RESPONSE', response)
            console.log('ERROR CATCH', error)
            // error.message
            // error.data.message
            return response = {
                data: undefined,
                error: {
                    message: error.message,
                    status: true,
                }
            } as ResponseCampaignData
        } finally {
            console.log('FINALLY RESPONSE', response)
            return response;
        }

    }, [load]);

    React.useEffect(() => {
        loadHotsiteData();
    }, [loadHotsiteData]);

    return (
        <ThemeContext.Provider value={{ hotsite, subdomainStatus, loadCampaignData }}>
            {children}
        </ThemeContext.Provider>
    )
}

export const useThemeContext = () => {
    const context = useContext(ThemeContext);

    return context;
}