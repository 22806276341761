import styled from 'styled-components';
import { theme } from '@theme';

const { border, color, typography } = theme;

export const Main = styled.div`
  border: 1px solid ${color.grey[200]};
  border-radius: ${border.default};
  margin-bottom: 15px;
  padding: 22px 30px;
  cursor: pointer;
  
  &:last-child {
      margin-bottom: 0;
    }
`;

export const Head = styled.div`
`;

export const Columns = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

export const Column = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const Title = styled.h1`
    font-size: 20px;
    font-weight: ${typography.fontWeightBold};
    color: ${color.grey[900]};
`;

export const ToggleIcon = styled.div`
    padding: 0 17px;
`;
