import React, { HTMLProps } from "react";
import { Main } from './styles';

interface ButtonProps {
  className?: string;
  link?: string;
  text: string;
  withBorder: boolean;
  outPage?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
}

const Button: React.FC<ButtonProps & HTMLProps<HTMLButtonElement>> =
  (props: ButtonProps) => {

    const { className, link, text, withBorder, onClick, children, outPage } = props;

    return (
      <Main
        withBorder={withBorder}
        className={`${className} ${outPage ? 'out-page' : ''} ${onClick ? "without-link" : ''}`}
        onClick={onClick}
      >

        {onClick
          ? outPage ? [children, text] : [text, children]
          : <a className="anchor" href={link} target="_blank" rel="noreferrer">{children ? [text, children] : text}</a>
        }

      </Main>
    )
  }

export { Button };