import { library } from '@fortawesome/fontawesome-svg-core'

import { faChevronLeft, faChevronRight, faTimes, faLongArrowRight, faBraille, faSmileBeam } from "@fortawesome/pro-regular-svg-icons";

import { faPlusCircle, faMinusCircle, faChevronDoubleRight, faBars } from "@fortawesome/pro-solid-svg-icons";

import { faSignIn, faExclamationTriangle, faArrowCircleLeft } from "@fortawesome/pro-light-svg-icons";


library.add(
    faChevronLeft,
    faChevronRight,
    faPlusCircle,
    faMinusCircle,
    faChevronDoubleRight,
    faTimes,
    faLongArrowRight,
    faBars,
    faSignIn,
    faBraille,
    faSmileBeam,
    faExclamationTriangle,
    faArrowCircleLeft
);